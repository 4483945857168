import { Component, Inject, OnInit, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CartService } from '@jfw-library/ecommerce/core';
import { isEventDateLocked } from 'business-logic';
import { Event, EventMember } from 'common-types';
import { EventManagerService } from '../../../services/event-manager-service';

@Component({
  selector: 'app-review-event-new-members-modal',
  templateUrl: './review-event-new-members.component.html',
  styleUrls: ['./review-event-new-members.component.scss'],
})
export class ReviewEventNewMembers implements OnInit {
  event!: Event;
  newMembers: EventMember[] = [];
  sendingInvites = signal(false);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { event: Event; newMembers: EventMember[] },
    private dialogRef: MatDialogRef<ReviewEventNewMembers>,
    private cartService: CartService,
    private eventManagerService: EventManagerService,
    private router: Router,
  ) {
    this.event = data.event;
    this.newMembers = data.newMembers;
  }

  ngOnInit(): void {
    console.log('Event');
    console.log(this.event);
    console.log('newMembers', this.newMembers);
  }

  async sendMemberInvites() {
    this.sendingInvites.set(true);
    try {
      // await of(undefined).pipe(delay(4000)).toPromise();
      const result = await Promise.allSettled(
        this.newMembers.map((member) => this.sendInvite(this.event, member)),
      );
      console.log('sendMemberInvites result', result);
      const numSuccess = result.filter((r) => r.status === 'fulfilled').length;
      console.log(numSuccess, 'invites sent successfully');
      const numFailed = result.filter((r) => r.status === 'rejected').length;
      if (numFailed) {
        console.error(numFailed, 'invites failed to send');
      }
      this.sendingInvites.set(false);
    } catch (error) {
      console.error('Failed to send invites', error);
      this.sendingInvites.set(false);
    }

    this.dialogRef.close();
  }

  isEventDateLocked(event: Event): boolean {
    return isEventDateLocked(event);
  }

  prepareCheckout() {
    console.log('hello?');
    this.dialogRef.close({});
    this.cartService.resetCheckoutState();
    this.cartService.checkoutCartSubject.next(undefined);
    this.router.navigate(['/checkout']);
  }

  /** queue a member-invite email in ComQ, then mark inviteSent via event api. */
  async sendInvite(event: Event, member: EventMember): Promise<void> {
    await this.eventManagerService
      .sendMemberInvite(event, member)
      .catch((e) => {
        console.error('Failed to send invite email for ', member?.id, e);
        throw e;
        // alert(
        //   'Sorry, there was an error sending the invite. Please try again or contact Customer Service.'
        // );
      });
  }
}
