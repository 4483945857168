<div class="modal-main">
  <div class="close-modal">
    <button mat-icon-button [mat-dialog-close]="true" class="close-button">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="modal-header-container">
    <h2 class="look-name">
      {{ look.title }}
    </h2>
  </div>

  <div class="mannequin">
    <app-look-mannequin [styles]="look.styles"></app-look-mannequin>
  </div>
</div>
