import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { EventService } from '@jfw-library/ecommerce/core';
import { Event } from 'common-types';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InStoreGuard {
  constructor(private eventService: EventService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.eventService.selectedEvent$.pipe(
      map((event: Event) => {
        if (event.inStoreInfo !== undefined) {
          return true;
        } else {
          if (Object.keys(event).length === 0) {
            // no event yet? TODO
            return true;
          }
          return false;
        }
      })
    );
  }
}
