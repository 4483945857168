import { Component, computed, effect, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService, EventService } from '@jfw-library/ecommerce/core';
import { StoreInfoModalComponent } from '@jfw-library/ecommerce/shared';
import {
  JfwEmailValidator,
  PhoneFormComponent,
} from '@jfw-library/shared/common-components/form-fields';
import {
  firebaseUserToShareEventDetails,
  isEventSharedWithRetailer,
  isPaidForMember,
} from 'business-logic';
import { Event, EventMember, Look, ShareEventDetails } from 'common-types';
import { debounceTime, map, ObservedValueOf, tap } from 'rxjs';
import { EventManagerService } from '../../services/event-manager-service';
import { UserEcomSettingsService } from '@jfw-library/shared/services';

interface ShareEventDetailsForm {
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  email: FormControl<string>;
  phone?: ObservedValueOf<PhoneFormComponent['formReady']>;
  // eventSize: FormControl<number | null>;
}

@Component({
  selector: 'app-share-event-details',
  templateUrl: './share-event-details.component.html',
  styleUrls: [
    './share-event-details.component.scss',
    '../../event-manager.component.scss',
  ],
})
export class ShareEventDetailsComponent {
  form = new FormGroup<ShareEventDetailsForm>({
    firstName: new FormControl('', {
      validators: Validators.required,
      nonNullable: true,
    }),
    lastName: new FormControl('', {
      validators: Validators.required,
      nonNullable: true,
    }),
    email: new FormControl('', {
      validators: [Validators.required, JfwEmailValidator.isValid()],
      nonNullable: true,
    }),
    // eventSize: new FormControl(0, [
    //   Validators.required,
    //   this.excludeZeroValidator(/^0/),
    // ]),
  });

  event = toSignal<Event>(this.eventService.selectedEvent$);
  inStoreInfo = computed(() => this.event()?.inStoreInfo);
  store = computed(() => this.inStoreInfo()?.store);
  members = computed(() => this.event()?.members ?? []);
  looks = computed(() => this.event()?.looks ?? []);
  looksMap = computed<Map<Look, EventMember[]>>(() => {
    const map = new Map<Look, EventMember[]>();
    for (let look of this.looks()) {
      let arr: EventMember[] = [];
      for (let member of this.members()) {
        if (member.memberLook?.id === look.id) {
          arr.push(member);
        }
      }
      map.set(look, arr);
    }
    return map;
  });

  user = toSignal(this.authService.user$);

  userEcomSettings = toSignal(this.userEcomSettingsService.userEcomSettings$);

  currentShareEventDetails = computed(() => {
    const inStoreInfo = this.inStoreInfo();
    const currentUser = this.user();
    const existingShareEventDetails = inStoreInfo?.shareEventDetails;
    const isSingleUser = this.event()?.isSingleUser;
    const eventSize =
      (existingShareEventDetails?.eventSize ?? isSingleUser)
        ? 1
        : this.event()?.members?.length;

    const shareEventDetails: ShareEventDetails = {
      ...(existingShareEventDetails
        ? existingShareEventDetails
        : currentUser
          ? firebaseUserToShareEventDetails(currentUser)
          : {
              firstName: '',
              lastName: '',
              email: '',
              phone: '',
            }),
      eventSize,
    };

    return shareEventDetails;
  });
  hasPaidMembers = computed(() =>
    this.event()?.members.some((member) => isPaidForMember(member)),
  );
  isEventSharedWithRetailer = computed(() =>
    isEventSharedWithRetailer(this.event()),
  );
  useCarousel = computed(() => this.looks().length > 3);
  routeToLastStep = effect(() => {
    if (this.store() === undefined) {
      this.eventService.routeToFirstStep();
    }
  });
  // initFormOnNewEvent = effect(() => {
  //   this.initializeForm(this.currentShareEventDetails());
  // });

  /** Reactively disable form and share button */
  disableForm = effect(() => {
    if (
      this.hasPaidMembers() ||
      this.isEventSharedWithRetailer() ||
      this.isSaving()
    ) {
      this.form.disable({ emitEvent: false });
      // this.eventManagerService.setShareEventDetailsFormState(false);
    }
  });

  /** Will disable form and share button (via disableForm effect), and show saving indicator */
  isSaving = signal(false);

  saved = signal(false);
  saveError = signal(false);

  /** Used by phone component to know when to disable */
  phoneDisabled = computed(
    () =>
      this.isEventSharedWithRetailer() ||
      this.hasPaidMembers() ||
      this.isSaving(),
  );

  isFormInvalid = signal(false);

  phone = '';
  validatorMsg = toSignal(
    this.eventManagerService.results$.pipe(
      map((validator) => validator?.msg ?? ''),
    ),
  );
  markTouched = effect(() => {
    if (this.isFormInvalid() || this.validatorMsg()) {
      this.form.markAllAsTouched();
    }
  });

  userEcomSettingsPhone: string | undefined;

  userEcomSettingsReady = signal(false);

  private readonly updateEventOnChanges = toSignal(
    this.form.valueChanges.pipe(
      debounceTime(1200),
      tap((value) => {
        console.log('updateEventOnChanges called');
        console.log('Value:', value);
        this.updateEvent(value);
      }),
    ),
  );

  constructor(
    private eventService: EventService,
    private dialog: MatDialog,
    private authService: AuthService,
    private eventManagerService: EventManagerService,
    private userEcomSettingsService: UserEcomSettingsService,
  ) {}

  async ngOnInit() {
    this.userEcomSettingsReady.set(false);

    let userEcomSettings =
      await this.userEcomSettingsService.apiGetEcomSettingsForUser();

    this.userEcomSettingsReady.set(true);
    console.log('UserEcomSettingsReady:', this.userEcomSettingsReady());

    if (
      userEcomSettings !== undefined &&
      userEcomSettings.phone !== undefined
    ) {
      if (userEcomSettings.phone.startsWith('1')) {
        userEcomSettings.phone = userEcomSettings.phone.slice(1);
      }

      if (userEcomSettings.phone.startsWith('+1')) {
        userEcomSettings.phone = userEcomSettings.phone.slice(2);
      }
      this.userEcomSettingsPhone = userEcomSettings.phone;
    }

    console.log(
      'CURRENT SHARE EVENT DETAILS:',
      this.currentShareEventDetails(),
    );

    this.initializeForm(this.currentShareEventDetails());

    if (
      (this.currentShareEventDetails().phone === undefined ||
        this.currentShareEventDetails().phone?.trim() === '') &&
      this.userEcomSettingsPhone !== undefined
    ) {
      console.log('Patching phone number');
      console.log(this.userEcomSettingsPhone);
      this.form.controls.phone?.patchValue({
        phone: this.userEcomSettingsPhone,
      });
      if (this.form.valid) {
        this.updateEvent(this.form.value);
      }
    }
  }

  excludeZeroValidator(input: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const noZeroCheck = input.test(control.value);
      return noZeroCheck ? { notAllowed: { value: control.value } } : null;
    };
  }

  setPhone(phone: string | undefined) {
    if (phone !== undefined && phone !== this.phone) {
      this.phone = phone;
    }
  }

  addChildForm<key extends keyof ShareEventDetailsForm>(
    name: key,
    group: Exclude<ShareEventDetailsForm[key], undefined>,
  ) {
    this.form.setControl(name, group, { emitEvent: false });
  }

  initializeForm(shareEventDetails: ShareEventDetails): void {
    console.log('Initializing form with shareEventDetails:', shareEventDetails);
    this.form.setValue(
      {
        firstName: shareEventDetails.firstName ?? '',
        lastName: shareEventDetails.lastName ?? '',
        email: shareEventDetails.email ?? '',
        // phone: { phone: this.phone ?? '' },
        phone: { phone: shareEventDetails.phone ?? '' },
        // eventSize: this.eventDetails.eventSize ?? 0,
      },
      { emitEvent: true },
    );

    const isValid = this.form.valid;
    this.eventManagerService.setShareEventDetailsFormState(isValid);
  }

  async updateEvent(
    valueChanges: ObservedValueOf<typeof this.form.valueChanges>,
  ): Promise<void> {
    console.log('updateEvent called');
    const eventId = this.event()?.id;
    if (!eventId) {
      console.error('No eventId found');
      return;
    }
    const currentDetails = this.currentShareEventDetails();

    // if (this.preventSave) {
    //   console.log('PreventSave is true.  Not saving.');
    //   return;
    // }

    if (this.hasPaidMembers() || this.isEventSharedWithRetailer()) {
      return;
    }

    if (this.form.valid) {
      this.isFormInvalid.set(false);
      const { firstName, lastName, email, phone: phoneForm } = valueChanges;

      // build the update object
      const updateShareEventDetails =
        this.eventManagerService.updateShareEventDetails;
      const shareEventDetailsUpdates: Parameters<
        typeof updateShareEventDetails
      >[1] = {};

      const firstNameChanged =
        firstName !== undefined &&
        firstName.trim() !== currentDetails?.firstName?.trim();
      if (firstNameChanged) {
        console.log('firstName changed');
        shareEventDetailsUpdates.firstName = firstName.trim();
      }

      const lastNameChanged =
        lastName !== undefined &&
        lastName.trim() !== currentDetails?.lastName?.trim();
      if (lastNameChanged) {
        console.log('lastName changed');
        shareEventDetailsUpdates.lastName = lastName.trim();
      }

      const emailChanged =
        email !== undefined && email.trim() !== currentDetails?.email?.trim();
      if (emailChanged) {
        console.log('email changed');
        shareEventDetailsUpdates.email = email.trim();
      }

      const phone = phoneForm?.phone;
      const phoneChanged =
        phone !== undefined && phone.trim() !== currentDetails?.phone?.trim();
      if (phoneChanged) {
        console.log('phone changed');
        shareEventDetailsUpdates.phone = phone.trim();
      }

      if (Object.keys(shareEventDetailsUpdates).length === 0) {
        console.log('No changes to save');
        if (this.saveError() === true) {
          // only remove "not saved" message if it was previously shown, otherwise leave "saved" message
          this.saved.set(false);
          this.saveError.set(false);
        }
        this.eventManagerService.setShareEventDetailsFormState(true);
        return;
      }

      this.isSaving.set(true);
      this.saved.set(false);
      this.saveError.set(false);

      console.log(
        'Saving with shareEventDetailsUpdates:',
        shareEventDetailsUpdates,
      );
      try {
        const newShareEventDetails = {
          ...currentDetails,
          ...shareEventDetailsUpdates,
        };
        const { updatedEvent } =
          await this.eventManagerService.updateShareEventDetails(
            eventId,
            newShareEventDetails,
          );
        console.log('details successfully updated');
        this.saved.set(true);
        this.saveError.set(false);
        console.log('Setting selected event with save result');
        this.eventService.setSelectedEventWithEvent(
          updatedEvent,
          'ShareEventDetailsComponent -- updateEvent()',
        );
        console.log('After setting selected event.');
        this.isSaving.set(false);
        this.form.enable({ emitEvent: false });
        this.eventManagerService.setShareEventDetailsFormState(true);
      } catch (error) {
        console.error('Error updating event details', error);
        alert(
          'Sorry, there was an error saving your event details.  Please try again or contact Customer Service.',
        );
        this.initializeForm(currentDetails); // reset the form to the previous values
        try {
          await this.eventService.getSelectedEvent(
            true,
            'EcomEventDetailsComponent -- updateEvent()',
          );
          this.isSaving.set(false);
          this.saved.set(false);
          this.saveError.set(true);
          this.form.enable({ emitEvent: false });
          return;
        } catch (error) {
          console.error('Error reloading event', error);
          this.isSaving.set(false);
          this.saved.set(false);
          this.saveError.set(true);
          alert('The page will reload now.');
          window.location.reload();
        }
      }
    } else {
      console.log('Details Form is not valid.');
      console.log('Form value:', valueChanges);
      this.eventManagerService.setShareEventDetailsFormState(false);
      this.saved.set(false);
      this.isFormInvalid.set(true);
      this.form.markAllAsTouched();
    }
  }

  onStoreInfo(): void {
    const store = this.store();
    if (!store) {
      console.error('No store found');
      return;
    }

    this.dialog.open(StoreInfoModalComponent, {
      panelClass: 'app-full-bleed-dialog',
      minWidth: '240px',
      maxWidth: '90%',
      maxHeight: '90%',
      data: store,
      autoFocus: false,
    });
  }
}
