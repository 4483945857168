import { Component, computed, effect, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EventService } from '@jfw-library/ecommerce/core';
import { debounceTime, firstValueFrom } from 'rxjs';
import { EventManagerService } from '../../services/event-manager-service';

interface WearerForm {
  firstName: FormControl<string>;
  lastName: FormControl<string>;
}

@Component({
  selector: 'app-wearers-info',
  templateUrl: './wearers-info.component.html',
  styleUrls: [
    './wearers-info.component.scss',
    '../../event-manager.component.scss',
  ],
})
export class WearersInfoComponent {
  public wearerForm = new FormGroup<WearerForm>({
    firstName: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    lastName: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  event = toSignal(this.eventService.selectedEvent$);
  eventId = computed(() => this.event()?.id);
  member = computed(() => this.event()?.members[0]);
  memberFirstName = computed(() => this.member()?.firstName?.trim());
  memberLastName = computed(() => this.member()?.lastName?.trim());
  memberId = computed(() => this.member()?.id);
  setForm = effect(() => {
    const member = this.member();
    console.log('Setting form values', member);
    this.wearerForm.setValue(
      {
        firstName: member?.firstName ?? '',
        lastName: member?.lastName ?? '',
      },
      { emitEvent: false, onlySelf: true },
    );
  });

  disableForm = effect(() => {
    const isSaving = this.isSaving();
    if (isSaving) {
      this.wearerForm.disable({ emitEvent: false });
    } else {
      this.wearerForm.enable({ emitEvent: false });
    }
  });

  valueChangesDebounced = toSignal(
    this.wearerForm.valueChanges.pipe(debounceTime(500)),
  );

  hasNameChanged = computed(() => {
    const formValues = this.valueChangesDebounced();
    const newFirstName = formValues?.firstName?.trim();
    const existingFirstName = this.memberFirstName();
    const newLastName = formValues?.lastName?.trim();
    const existingLastName = this.memberLastName();

    const nameChanged =
      newFirstName !== undefined &&
      newLastName !== undefined &&
      (existingFirstName !== newFirstName || existingLastName !== newLastName);

    // console.log({
    //   existingFirstName,
    //   newFirstName,
    //   existingLastName,
    //   newLastName,
    //   nameChanged,
    // });
    return nameChanged;
  });

  saveOnChanges = effect(
    () => {
      const nameChanged = this.hasNameChanged();
      const valueChangesDebounced = this.valueChangesDebounced(); // needed because hasNameChanged won't emit if it has the same value as before
      if (nameChanged) {
        this.updateEventMemberDetails();
      } else {
        // console.log('No changes to save.');
      }
    },
    {
      allowSignalWrites: true,
    },
  );

  isSaving = signal(false);
  saved = signal(false);
  errorSaving = signal(false);

  async updateEventMemberDetails() {
    console.log('Updating event member details');
    const eventId = this.eventId();
    const memberId = this.memberId();
    if (!eventId || !memberId) {
      console.log('Missing eventId or member id.');
      return;
    }

    if (!this.wearerForm.valid) {
      console.log('Form is not valid.');
      return;
    }

    this.isSaving.set(true);
    this.saved.set(false);
    this.errorSaving.set(false);

    const { firstName, lastName } = this.wearerForm.value;

    try {
      // const wait = await new Promise((resolve) => setTimeout(resolve, 2000));
      const { updatedEvent } = await firstValueFrom(
        this.eventManagerService.updateMemberDetails(eventId, memberId, {
          firstName,
          lastName,
        }),
      );
      this.eventService.setSelectedEventWithEvent(
        updatedEvent,
        'WearersInfoComponent - updateEventMemberDetails',
      );
      this.isSaving.set(false);
      this.saved.set(true);
    } catch (error) {
      console.error('Error updating member details.', error);
      this.isSaving.set(false);
      this.errorSaving.set(true);
      const firstNameBeforeChange = this.memberFirstName();
      const lastNameBeforeChange = this.memberLastName();
      if (firstNameBeforeChange && lastNameBeforeChange) {
        console.log('Reverting form values to previous values.');
        this.wearerForm.setValue(
          {
            firstName: firstNameBeforeChange,
            lastName: lastNameBeforeChange,
          },
          { emitEvent: false },
        );
      }
    }
  }

  constructor(
    private eventManagerService: EventManagerService,
    private eventService: EventService,
    private dialog: MatDialog,
    private router: Router,
  ) {}
}
