<app-event-header [eventStep]="eventStep()"></app-event-header>

<div class="nav-buttons-desktop">
  <div>
    <button
      id="previous-button"
      *ngIf="!eventStep()?.hidePrevBtn"
      (click)="onPrev()"
      mat-flat-button
      [disabled]="previousDisabled()"
      color="primary"
      data-cy="previous"
    >
      <span>
        <ng-container *ngIf="!showPreviousSpinner(); else spinner">
          <mat-icon class="prev">keyboard_arrow_left</mat-icon>
        </ng-container>
        {{ eventStep()?.prevBtnName || "Prev" }}
      </span>
    </button>
  </div>
  <div
    *ngIf="eventStep()?.label"
    style="flex: 1 1 40%; text-align: center; padding-right: 0px"
  >
    <h1 class="desktop-step-label" attr.data-cy="{{ eventStep()?.route }}">
      {{ eventStep()?.label }}
    </h1>
  </div>
  <div>
    <button
      id="next-button"
      *ngIf="!eventStep()?.hideNextBtn"
      (click)="onNext()"
      [color]="showWarning() ? 'warn' : 'primary'"
      mat-flat-button
      data-cy="next"
      [disabled]="nextDisabled()"
    >
      <span
        >{{ eventStep()?.nextBtnName || "Next" }}
        <ng-container *ngIf="!showNextSpinner(); else spinner">
          <mat-icon class="next">keyboard_arrow_right</mat-icon>
        </ng-container>
      </span>
    </button>
  </div>
</div>
<div *ngIf="showEventLockMsg()" class="event-lock-msg-container">
  <div class="date-lock-spacer"></div>
  <span>{{ dateLockValidatorMsg() }}</span>
</div>

<div class="body">
  <div class="stepper">
    <app-event-stepper
      #stepper
      [hidden]="hideStepper()"
      (stepperEvent)="setEventStep($event)"
      (hideStepper)="setHideStepper($event)"
    ></app-event-stepper>
  </div>

  <div class="nav-buttons-mobile">
    <div style="flex: 1 1 25%; margin-left: 0px">
      <button
        mat-button
        color="primary"
        *ngIf="!eventStep()?.hidePrevBtn"
        (click)="onPrev()"
        [disabled]="previousDisabled()"
        data-cy="previous"
      >
        <span>
          <ng-container *ngIf="!showPreviousSpinner(); else spinner">
            <mat-icon class="prev">keyboard_arrow_left</mat-icon>
          </ng-container>
          {{ eventStep()?.prevBtnName || "Prev" }}
        </span>
      </button>
    </div>

    <div style="flex: 1 1 40%; text-align: center; padding-right: 0px">
      <h1 attr.data-cy="{{ eventStep()?.route }}">
        {{ eventStep()?.label }}
      </h1>
    </div>

    <div style="flex: 1 1 25%; margin-right: 0px; text-align: right">
      <button
        mat-button
        *ngIf="!eventStep()?.hideNextBtn"
        (click)="onNext()"
        [color]="showWarning() ? 'warn' : 'primary'"
        [disabled]="nextDisabled()"
        data-cy="next"
      >
        <span
          >{{ eventStep()?.nextBtnName || "Next" }}
          <ng-container *ngIf="!showNextSpinner(); else spinner">
            <mat-icon class="next">keyboard_arrow_right</mat-icon>
          </ng-container>
        </span>
      </button>
    </div>
  </div>
  <div class="event-lock-msg-container mobile" *ngIf="showEventLockMsg()">
    <span>{{ dateLockValidatorMsg() }}</span>
  </div>
  <div class="step-content" [style.width]="hideStepper() ? '60%' : '70%'">
    <router-outlet></router-outlet>
  </div>

  <div class="spacer" [style.width]="hideStepper() ? '20%' : '10%'"></div>
</div>

<ng-template #spinner>
  <mat-spinner
    diameter="20"
    color="accent"
    style="display: inline-block; margin-bottom: -5px"
  ></mat-spinner>
</ng-template>
