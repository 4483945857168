import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  computed,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  AuthService,
  CartService,
  EventService,
} from '@jfw-library/ecommerce/core';
import {
  getIndividualView,
  getMemberView,
  isUserOrganizerOrAdminForEvent,
} from 'business-logic';
import {
  DealerPortalEnvironment,
  DealerUserEvent,
  EcommerceMainEnvironment,
  EventMember,
  Look,
} from 'common-types';
import { Subscription } from 'rxjs';
import { EventManagerService } from '../../services/event-manager-service';
import { ReviewEventNewMembers } from '../modals/review-event-new-members/review-event-new-members.component';

@Component({
  selector: 'app-review-event',
  templateUrl: './review-event.component.html',
  styleUrls: [
    './review-event.component.scss',
    '../../event-manager.component.scss',
  ],
})
export class ReviewEventComponent implements OnInit, AfterViewInit, OnDestroy {
  user = toSignal(this.authService.user$);
  event = toSignal(this.eventService.selectedEvent$);
  members = computed(() => this.event()?.members ?? []);
  looks = computed(() => this.event()?.looks ?? []);
  unassignedMembers = computed(() =>
    this.members().filter((member) => !member.memberLook),
  );
  membersWithoutLooksExist = computed(() => {
    const members = this.members();
    if (members.length === 0) return false;
    return this.members().some((member) => {
      return (
        member.memberLook === undefined || member.memberLook.title === undefined
      );
    });
  });
  dealerUserEvent!: DealerUserEvent;
  subscription = new Subscription();
  dealerPortal = this.environment.dealerPortal;
  individualView = computed(() => {
    const event = this.event();
    if (!event) return false;
    return getIndividualView(event);
  });
  memberView = computed(() => {
    const event = this.event();
    const user = this.user();
    if (!event) return false;
    return getMemberView(event, user);
  });
  map = new Map<Look, EventMember[]>();
  dealerLookMemberMap = new Map<Look, EventMember[]>();
  eventOrganizer = computed(() => {
    const event = this.event();
    if (!event) return undefined;
    return event.admins.filter((admin) => {
      return admin.id !== event.createdByUserId;
    })[0];
  });
  isOrganizer = computed(() =>
    isUserOrganizerOrAdminForEvent(this.event(), this.user()),
  );
  activeLookIndex: number = 0;
  transferError!: string;
  validatorMsg = '';
  nextClicked = false;
  isMobile = false;

  constructor(
    @Inject('environment')
    private environment: EcommerceMainEnvironment | DealerPortalEnvironment,
    private cartService: CartService,
    private cdr: ChangeDetectorRef,
    private eventManagerService: EventManagerService,
    private dialog: MatDialog,
    private eventService: EventService,
    private authService: AuthService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.breakpointObserver.observe(Breakpoints.XSmall).subscribe({
        next: (result) => (this.isMobile = result.matches),
      }),
    );

    if (!this.dealerPortal) {
      this.cartService.resetCheckoutState();
    }

    this.subscription.add(
      this.eventService.selectedEvent$.subscribe({
        next: (event) => {
          console.log('new event received in ReviewEventComponent.');
          // this.event = event;
          if (this.dealerPortal) {
            // this.setEventOrganizer();
            this.setMapForDealer();
          }

          // this.individualView = getIndividualView(this.event);
          // this.memberView = getMemberView(this.event);

          this.setMap();
          // this.getUnassignedMembers();
        },
      }),
    );

    if (!this.dealerPortal) {
      this.checkForNewMembers();
    }

    // this.subscription.add(
    //   this.eventService.nextClicked$.subscribe({
    //     next: () => this.onNext(),
    //   })
    // );
  }

  ngAfterViewInit(): void {
    if (!this.dealerPortal) {
      localStorage.removeItem('checkout_state');
      // initialize checkout state
      this.cartService.initializeCheckoutState();
      // this.cartService.checkoutStatusSubject.next(
      //   this.cartService.getStartingCheckoutState()
      // );
    }

    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // onNext(): void {
  //   this.nextClicked = true;
  //   if (this.dealerPortal) {
  //     this.sendInviteToOrganizer();
  //   } else {
  //     this.prepareCheckout();
  //   }
  // }

  // validate(): void {
  //   const validator = this.validationService.isEventStepValid(
  //     this.event,
  //     'transfer-event'
  //   );
  //   this.validatorMsg = validator.msg;
  //   if (validator.valid) {
  //     this.eventService.canProceed$.next(validator.valid);
  //   }
  // }

  useCarousel(): boolean {
    return this.members().length > 3 || this.isMobile;
  }

  hasAnyonePaid() {
    let hasPaid: boolean = false;

    this.members().forEach((member) => {
      if (
        member.memberProgress !== undefined &&
        member.memberProgress.paid !== undefined
      ) {
        hasPaid = true;
      }
    });

    return hasPaid;
  }

  checkForNewMembers() {
    if (!this.isOrganizer()) {
      console.log('not organizer');
      return;
    }

    if (!this.hasAnyonePaid()) {
      console.log('no one has paid');
      return;
    }

    const newMembers = this.members().filter((member) => {
      return (
        member.memberProgress === undefined ||
        (member.memberProgress.inviteSent === undefined &&
          member.memberProgress.paid === undefined)
      );
    });

    if (newMembers.length > 0) {
      console.log('new members found');
      this.dialog.open(ReviewEventNewMembers, {
        data: {
          event: this.event(),
          newMembers,
        },
        disableClose: true,
        width: '340px',
      });
    } else {
      console.log('no new members');
    }
  }

  // prepareCheckout() {
  //   this.cartService.resetCheckoutState();
  //   this.cartService.checkoutCartSubject.next(undefined);
  //   this.eventService.canProceed$.next(true);
  // }

  setMap(): void {
    this.map.clear();

    const user = this.user();

    const userId = user?.uid ?? '';

    let displayedLookCounter: number = 0;

    for (let look of this.looks()) {
      let membersWithThisLook: EventMember[] = [];

      let foundUser: boolean = false;

      for (let member of this.members()) {
        if (member.memberLook?.id === look.id) {
          membersWithThisLook.push(member);

          if (member.userId === userId) {
            foundUser = true;
          }
        }
      }

      if (membersWithThisLook.length > 0) {
        this.map.set(look, membersWithThisLook);

        if (foundUser) {
          this.activeLookIndex = displayedLookCounter;
        }

        displayedLookCounter = displayedLookCounter + 1;
      }
    }

    console.log('ActiveLookIndex: ' + this.activeLookIndex);
  }

  // getLookActiveIndex(looks: Look[]) {
  //   let index: number = 0;
  //   let lookCounter: number = 0;

  //   console.log('Looking for userId: ' + this.user?.uid);

  //   for (let look of this.event.looks) {
  //     console.log('Look: ' + look.title);
  //     for (let member of this.event.members) {
  //       if (
  //         member.userId === this.user?.uid &&
  //         member.memberLook?.id === look.id
  //       ) {
  //         console.log('Found MATCH: ' + lookCounter);
  //         index = lookCounter;
  //       }
  //     }
  //     lookCounter = lookCounter + 1;
  //   }

  //   console.log('activeLookIndex: ' + index);
  //   this.activeLookIndex = index;
  // }

  setMapForDealer(): void {
    for (let look of this.looks()) {
      let arr: EventMember[] = [];

      for (let member of this.members()) {
        if (member.memberLook?.id === look.id) {
          arr.push(member);
        }
      }

      //if (arr.length > 0) {
      this.dealerLookMemberMap.set(look, arr);
      //}
    }
  }

  // getUnassignedMembers(): void {
  //   this.unassignedMembers = [];
  //   this.members()?.forEach((member: EventMember) => {
  //     if (!member.memberLook) {
  //       this.unassignedMembers.push(member);
  //     }
  //   });
  // }

  // async sendInviteToOrganizer(): Promise<void> {
  //   let response;
  //   const organizer = this.event.admins.filter(
  //     (admin) => admin.userId !== this.user?.uid
  //   )[0];
  //   try {
  //     response = await this.communicationQueueService.queueTransferEventEmail(
  //       this.event,
  //       organizer.email,
  //       organizer.name
  //     );
  //   } catch (error) {
  //     this.eventService.transferEventError$.next(true);
  //     this.validate();
  //   }

  //   if (response !== undefined) {
  //     this.dealerEventService.markEventAsTransferred(this.event.id).subscribe();
  //     this.validate();
  //   } else {
  //     console.log('unable to send transfer email');
  //   }
  // }

  // setEventOrganizer(): void {
  //   this.eventOrganizer = this.event.admins.filter((admin) => {
  //     return admin.id !== this.event.createdByUserId;
  //   })[0];
  // }

  // membersWithoutLooksExist(): boolean {
  //   let membersWithoutLooks: boolean = false;

  //   this.members().forEach((member) => {
  //     if (
  //       member.memberLook === undefined ||
  //       member.memberLook.title === undefined
  //     ) {
  //       membersWithoutLooks = true;
  //     }
  //   });

  //   return membersWithoutLooks;
  // }
}
