import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  StepperOrientation,
  StepperSelectionEvent,
} from '@angular/cdk/stepper';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  computed,
  inject,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ThemePalette } from '@angular/material/core';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService, EventService } from '@jfw-library/ecommerce/core';
import { ValidationService } from '@jfw-library/ecommerce/shared';
import { getEventSteps, getIndividualView } from 'business-logic';
import { Event, EventStep } from 'common-types';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { EventManagerService } from '../../services/event-manager-service';

@Component({
  selector: 'app-event-stepper',
  templateUrl: './event-stepper.component.html',
  styleUrls: [
    './event-stepper.component.scss',
    '../../event-manager.component.scss',
  ],
})
export class EventStepperComponent implements OnInit, AfterViewInit, OnDestroy {
  user = toSignal(this.authService.user$);

  event = toSignal(this.eventService.selectedEvent$);

  // @Input()
  // set event(value: Event) {
  //   console.log('event received in stepper:', value);
  //   this._event = value;
  //   this.initializeStepper(value);
  // };
  // get event(): Event {
  //   return this._event;
  // }
  @Output() hideStepper = new EventEmitter<boolean>();
  @Output() stepperEvent = new EventEmitter<EventStep>();
  @ViewChild('stepper') stepper: MatStepper | undefined;

  eventSteps = computed(() => {
    const event = this.event();
    if (!event) return [];
    const user = this.user();
    return getEventSteps(event, this.environment.dealerPortal, user);
  });
  canProceed = true;
  subs: Subscription[] = [];
  hideStepperVal = signal(false);
  parentRoute = '';
  childRoute = '';
  lastRoute = '';
  selectedIndex = -1;
  isSaving = false;
  stepperOrientation$!: Observable<StepperOrientation>;
  assignLooksIsEmpty = true;
  hasInvalidStep = false;
  showWarning = false;
  private document: Document = inject(DOCUMENT);

  constructor(
    @Inject('environment') private environment: any,
    private eventService: EventService,
    private eventManagerService: EventManagerService,
    private authService: AuthService,
    private validationService: ValidationService,
    private router: Router,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.stepperOrientation$ = this.breakpointObserver
      .observe(Breakpoints.XSmall)
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));

    this.lastRoute = this.router.url.substring(
      this.router.url.lastIndexOf('/') + 1,
    );
    this.setChildRoute();
    // this.initializeStepper();// moved to event input setter so that initialization happens after event is set (but before ngOnInit) and on any new event
    // this.initializeStepsInteracted(); // moved to event input setter
    // this.setButtons(); // moved to event input setter

    this.subs.push(
      this.eventService.selectedEvent$.subscribe((event) => {
        console.log('event received in stepper:', event);
        this.initializeStepper(event);
      }),
    );

    this.subs.push(
      this.eventService.canProceed$.subscribe({
        next: (canProceed) => this.onCanProceed(canProceed),
      }),
    );

    this.subs.push(
      this.eventService.forceNextStep$.subscribe({
        next: () => this.onNext(),
      }),
    );

    this.subs.push(
      this.router.events.subscribe({
        next: (val) => {
          if (val instanceof NavigationEnd) {
            console.log('router event in stepper:', val.url);
            this.setChildRoute();
            this.initializeStepper(this.event());
            // this.setButtons();
            // this.scrollToStep();
            // if (
            //   this.lastRoute === 'details' ||
            //   this.lastRoute === 'track-progress' ||
            //   this.lastRoute === 'confirmation'
            // ) {
            //   this.setStepsCompleted();
            // }
            this.lastRoute = val.url.substring(val.url.lastIndexOf('/') + 1);
          }
        },
      }),
    );

    this.subs.push(
      this.eventManagerService.results$.subscribe((validator) => {
        this.hasInvalidStep = validator?.valid === false;
        if (validator?.valid == false) {
          console.log(
            `Resetting stepper to current step ${
              this.stepper?.steps.get(this.selectedIndex)?.label
            } on invalid step result`,
          );
          setTimeout(() => {
            if (this.stepper) {
              this.stepper.selectedIndex = this.selectedIndex;
            }
          }, 0);
        }
      }),
    );

    this.subs.push(
      this.eventManagerService.isSaving$.subscribe((saving) => {
        this.isSaving = saving;
      }),
    );

    this.subs.push(
      this.eventManagerService.assignLooksToUpdate$.subscribe((val) => {
        this.assignLooksIsEmpty = val.length === 0;
      }),
    );

    this.subs.push(
      this.eventManagerService.showWarning$.subscribe((val) => {
        this.showWarning = val;
      }),
    );
  }

  ngAfterViewInit(): void {
    this.setChildRoute();
    if (!this.hideStepperVal()) {
      this.scrollToStep();
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  getTextColor(index: number): string {
    return index === this.selectedIndex && this.showWarning
      ? 'warn'
      : 'primary';
  }

  getIconColor(index: number): ThemePalette {
    return index === this.selectedIndex && this.showWarning
      ? 'warn'
      : 'primary';
  }

  getStepCompleted(index: number) {
    if (this.stepper) {
      const matStep = this.stepper.steps.toArray()[index];
      const stepsArray = this.stepper.steps.toArray();
      const currentSelectedStep = stepsArray[this.selectedIndex];
      const currentSelectedEventStep = this.eventSteps()[this.selectedIndex];
      const route = this.eventSteps()[index].route;
      const validator = this.validationService.isEventStepValid(
        this.eventService.selectedEvent!,
        route,
      );
      if (!matStep?.interacted) {
        return false;
      }
      return (
        (matStep.interacted && validator.valid) ||
        (currentSelectedEventStep?.route === 'assign-looks' &&
          this.showWarning === false &&
          route === 'assign-looks')
      );
    } else return false;
  }

  getStepInteracted(index: number) {
    if (this.stepper) {
      const matStep = this.stepper.steps.toArray()[index];
      const stepsArray = this.stepper.steps.toArray();
      const currentSelectedStep = stepsArray[this.selectedIndex];
      const currentSelectedEventStep = this.eventSteps()[this.selectedIndex];
      const route = this.eventSteps()[index].route;
      const validator = this.validationService.isEventStepValid(
        this.eventService.selectedEvent!,
        route,
      );
      return validator.valid === true && index <= this.selectedIndex;
    } else return false;
  }

  /** During the initialization process,
   * this method sets the interacted property of each step in the stepper to true
   * if the step is before or equal to the current step and the step is valid.
   * This is necessary so that the stepper will show the checkmark icon for each step that is valid.
   * The [completed] property of each step is set in the setStepsCompleted() method, but even if the step is completed,
   * the checkmark icon will not show if the interacted property is false.
   * If the step is not valid, the interacted property will remain unchanged.
   */
  private initializeStepsInteracted() {
    console.log('setStepsInteracted');
    if (this.stepper) {
      console.log('stepper exists');
      const stepsArray = this.stepper.steps.toArray();
      let stepIndex = 0;
      for (let matStep of stepsArray) {
        const route = this.eventSteps()[stepIndex].route;
        const validator = this.validationService.isEventStepValid(
          this.eventService.selectedEvent!,
          route,
        );
        matStep.interacted =
          stepIndex <= this.selectedIndex
            ? validator.valid
            : matStep.interacted;
        stepIndex++;
      }
    }
    console.log(
      this.stepper?.steps
        .toArray()
        .map((step) => ({ label: step.label, interacted: step.interacted })),
    );
  }

  setChildRoute() {
    const segments = this.router.url.split('/');
    this.childRoute = segments[segments.length - 1].toLowerCase();
  }

  /**
   * This method initializes the stepper with the correct steps and selectedIndex.
   * It also sets the hideStepperVal property to true if the selectedIndex could not be found, or if there are only 2 steps or less, or if the last step is selected.
   */
  async initializeStepper(event: Event | undefined): Promise<void> {
    console.log('initializeStepper');
    if (!event) return;
    // this.eventSteps = getEventSteps(event, this.environment.dealerPortal, this.user());
    console.log('eventSteps:', this.eventSteps());

    this.parentRoute = `/event/${this.route.snapshot.url[0].path}`;
    const activeRoute = this.router.url;

    console.log('activeRoute:', activeRoute);
    console.log('parentRoute:', this.parentRoute);

    this.selectedIndex = this.eventSteps().findIndex(
      (step) => `${this.parentRoute}/${step.route}` === activeRoute,
    );

    console.log('selectedIndex: ', this.selectedIndex);

    if (this.stepper && this.selectedIndex !== -1) {
      console.log('setting stepper.selectedIndex to:', this.selectedIndex);
      setTimeout(() => {
        if (this.stepper && this.selectedIndex !== -1) {
          this.stepper.selectedIndex = this.selectedIndex;
        }
      });
    }

    const stepNotFound = this.selectedIndex === -1;
    const otherEventManagerRoutes = [
      'track-progress',
      'details',
      'review-measurements',
      'confirmation',
    ]

    const isOtherEventMangerRoute = otherEventManagerRoutes.some(route => activeRoute.includes(route));

    const shouldRedirectToFirstStep = stepNotFound && !isOtherEventMangerRoute;

    console.log({ stepNotFound, isOtherEventMangerRoute, shouldRedirectToFirstStep });

    if (shouldRedirectToFirstStep) {
      console.log(
        'current route not in eventSteps. Navigating to first step instead.',
      );
      this.selectedIndex = 0; // set selectedIndex to 0 so that the first step is selected
      await this.router.navigate([
        `${this.parentRoute}/${this.eventSteps()[0].route}`,
      ]);
      return;
    }

    const isLastStep = this.selectedIndex === this.eventSteps().length - 1;
    const onlyTwoStepsOrLess = this.eventSteps().length <= 2;

    const hideStepper = isLastStep || onlyTwoStepsOrLess || stepNotFound;
    console.log({ isLastStep, onlyTwoStepsOrLess, hideStepper });
    this.hideStepperVal.set(hideStepper);

    // Change the selectedIndex to the first invalid step if there is one
    await this.reRouteToFirstInvalidStep();

    this.initializeStepsInteracted();
    this.setButtons(event);

    this.cdr.detectChanges();
    this.hideStepper.emit(this.hideStepperVal());
    this.scrollToStep();
  }

  /**
   * This method sets default button names and other properties of the eventSteps for specific use-cases.
   */
  setButtons(event: Event): void {
    console.log('setButtons');
    let eventStep: EventStep = {};
    if (this.selectedIndex !== -1) {
      eventStep = this.eventSteps()[this.selectedIndex];
    }
    /*  // EventManager buttons now have these as default if BtnName is not set
    if (!eventStep.prevBtnName) {
      eventStep.prevBtnName = 'Prev';
    }
    if (!eventStep.nextBtnName) {
      eventStep.nextBtnName = 'Next';
    } */

    // edit event details/track progress/review-measurements override
    if (this.hideStepperVal() && this.selectedIndex === -1) {
      eventStep.hidePrevBtn = false;
      eventStep.hideNextBtn = true;
      eventStep.prevBtnName = 'Prev';
      eventStep.altPrevAction = 'RETURN_TO_EVENT';
    }

    // // Manually setting label for specific use-cases (if applicable)
    if (eventStep.label === undefined || eventStep.label === '') {
      switch (this.childRoute) {
        case 'track-progress':
          eventStep.label = 'Track Progress';
          break;
        case 'details':
          eventStep.label = 'EVENT DETAILS';
          break;
        default:
          break;
      }
    }

    // For individual users display return to event as long as they're not paid.
    if (getIndividualView(event) && this.selectedIndex === -1) {
      if (event.members[0].memberProgress?.paid === undefined) {
        eventStep.hidePrevBtn = false;
        eventStep.hideNextBtn = true;
        eventStep.prevBtnName = 'Prev';
        eventStep.altPrevAction = 'RETURN_TO_EVENT';
      } else {
        // else hide it. see EXT-932.
        eventStep.hidePrevBtn = true;
        eventStep.hideNextBtn = true;
      }
    }

    this.stepperEvent.emit(eventStep);
  }

  /** During initialization,
   * this method finds the first invalid step and re-routes to it if it is before the current step.
   */
  private async reRouteToFirstInvalidStep() {
    console.log('Checking if re-routing to earlier invalid step is necessary');

    const isLastStep = this.selectedIndex === this.eventSteps().length - 1;
    if (isLastStep) {
      console.log('This is the last step. No re-routing necessary.');
      return;
    }
    const currentRoute = this.router.url.split('/');

    const event = this.event();

    /// Removing this check for now. It's preventing the user from going to the details page.
    if (
      currentRoute[currentRoute.length - 1] !== 'confirmation' &&
      currentRoute[currentRoute.length - 1] !== 'details' &&
      event?.inStoreInfo?.dateSharedWithStore !== undefined
    ) {
      // console.log(
      //   '!! TRIED TO GO TO IN-STORE EVENT AFTER SUBMISSION (event-stepper) !!',
      // );
      this.router.navigate(['/event/' + event?.id + '/confirmation']);
      return;
    }

    const onTrackProgress =
      currentRoute[currentRoute.length - 1] === 'track-progress';
    const onEventDetails = currentRoute[currentRoute.length - 1] === 'details';
    const onReviewMeasurements =
      currentRoute[currentRoute.length - 1] === 'review-measurements';

    if (onTrackProgress || onEventDetails || onReviewMeasurements) {
      console.log(
        'This is the track-progress, details, or review-measurements route. No re-routing necessary.',
      );
      return;
    }
    const firstInvalidStepIndex = this.getFirstInvalidStep(this.eventSteps());
    if (
      firstInvalidStepIndex !== -1 &&
      firstInvalidStepIndex < this.selectedIndex
    ) {
      const firstInvalidEventStep = this.eventSteps()[firstInvalidStepIndex];
      console.log(
        'Re-routing to firstInvalidEventStep: ',
        firstInvalidEventStep.route,
      );
      this.router.navigate([
        `${this.parentRoute}/${firstInvalidEventStep.route}`,
      ]);
      this.selectedIndex = firstInvalidStepIndex;
      return;
    }
    console.log('No re-routing necessary');
  }

  private getFirstInvalidStep(stepsArray: EventStep[]) {
    let stepIndex = 0;
    for (let eventStep of stepsArray) {
      const route = eventStep.route;
      const validator = this.validationService.isEventStepValid(
        this.eventService.selectedEvent!,
        route,
      );

      if (validator.valid === false) {
        return stepIndex;
      }
      stepIndex++;
    }
    return -1; // no invalid steps
  }

  onPrev(): void {
    this.stepper?.previous();
  }

  onNext(): void {
    if (this.selectedIndex === this.eventSteps().length - 2) {
      const route = this.eventSteps()[this.selectedIndex + 1].route;
    } else {
      this.stepper?.next();
    }
  }

  /** This fires anytime a step is selected (either via an actual user input click or via the stepper.selectedIndex being programmatically changed) */
  onChange($event: StepperSelectionEvent): void {
    // avoid double clicks and an infinite loop that could occur because the router change sets the stepper.selectedIndex (in initializeStepper() ), which triggers this method again.
    if (this.selectedIndex === $event.selectedIndex) {
      console.log('onChange: selectedIndex is the same. Returning.');
      return;
    }
    const eventStep = this.eventSteps()[$event.selectedIndex];
    this.eventManagerService.stepRequested(eventStep);
  }

  onCanProceed(canProceed: boolean): void {
    this.canProceed = canProceed;
    if (this.stepper) {
      const stepsArray: MatStep[] = this.stepper.steps?.toArray();
      stepsArray[this.selectedIndex].completed = this.canProceed;
      if (!this.canProceed) {
        for (let i = this.selectedIndex + 1; i < stepsArray.length; i++) {
          stepsArray[i].completed = false;
        }
      }
    }
  }

  scrollToStep(): void {
    const stepId = this.stepper?._getStepLabelId(this.selectedIndex);
    if (stepId) {
      const stepElement = this.document.getElementById(stepId);
      if (stepElement) {
        setTimeout(() => {
          stepElement.scrollIntoView({
            block: 'nearest',
            inline: 'start',
            behavior: 'smooth',
          });
        }, 500);
      }
    }
  }
}
