<!-- ECOMMERCE -->
<div *ngIf="!dealerPortal" class="main-event">
  <!-- <h1 class="hide-xs" data-cy="add-members">Add Members</h1> -->
  <div class="grooms-free" *ngIf="event.eventType === 'Wedding'">
    Groom Rents Free! See Details
    <mat-icon
      class="grooms-free-tooltip"
      matTooltip="After 6 orders in the party are paid and shipped, the groom will receive a credit equal to the cost of their rental (up to $250). Fees and shipping not included."
      matTooltipPosition="right"
      >help_outline
    </mat-icon>
  </div>
  <div class="grooms-free" *ngIf="event.eventType === 'Quinceañera'">
    Honor Escort Rents Free! See Details
    <mat-icon
      class="grooms-free-tooltip"
      matTooltip="After 6 orders in the party are paid and shipped, the honor escort will receive a credit equal to the cost of their rental (up to $250). Fees and shipping not included."
      matTooltipPosition="right"
      >help_outline
    </mat-icon>
  </div>
  <div class="event-step-copy">
    <p>Add and organize your party members.</p>
    <p *ngIf="this.hasPaidMember()">
      To delete a paid member, please contact Customer Service at 866-783-5767.
    </p>
  </div>

  <div *ngIf="validatorMsg" class="event-step-validator-msg">
    <strong>{{ validatorMsg }}</strong>
  </div>

  <!-- Add/Edit Member -->
  <mat-accordion>
    <mat-expansion-panel
      #panel
      hideToggle
      (opened)="openExpansionPanel()"
      (closed)="resetForm()"
      [expanded]="expansionPanelOpen"
    >
      <mat-expansion-panel-header
        data-cy="add-members-header-panel"
        class="mat-expansion-panel-header"
      >
        <mat-panel-title>
          <span *ngIf="editIndex === -1">ADD MEMBER</span>
          <span *ngIf="editIndex !== -1">EDIT MEMBER</span>
          <mat-icon *ngIf="!expansionPanelOpen">add</mat-icon>
          <mat-icon *ngIf="expansionPanelOpen">clear</mat-icon>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="form" #formDirective="ngForm" (ngSubmit)="onSave()">
        <div class="grid">
          <mat-form-field appearance="outline">
            <mat-label>First Name</mat-label>
            <input
              matInput
              formControlName="firstName"
              maxlength="20"
              required
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Last Name</mat-label>
            <input
              matInput
              formControlName="lastName"
              maxlength="20"
              required
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Role</mat-label>
            <!-- Invisble anchor for redirecting focus to role field -->
            <input
              *ngIf="isMobile"
              style="width: 0; height: 0; opacity: 0; position: absolute"
              (focus)="roleFieldFocusById('role')"
            />
            <!-- Invisble anchor for redirecting focus to role field -->
            <mat-select #measselect id="role" formControlName="role" required>
              <mat-option
                tabindex="0"
                *ngFor="let role of getRoles()"
                [value]="role.name"
              >
                {{ role.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <app-phone-form
            appearance="outline"
            (formReady)="addChildForm('phone', $event)"
            (valueChange)="setPhone($event)"
          ></app-phone-form>
          <div>
            <mat-form-field appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput formControlName="email" required />
              <mat-error *ngIf="getFirstError(form.controls.email) as error">
                {{ error }}
              </mat-error>
              <!-- <mat-error>Please enter a valid email address.</mat-error> -->
            </mat-form-field>
          </div>
        </div>
        <mat-action-row>
          <mat-checkbox *ngIf="displayThisIsMe" formControlName="thisIsMe"
            >This is me</mat-checkbox
          >
          <div *ngIf="!displayThisIsMe">&nbsp;</div>
          <div>
            <button
              mat-stroked-button
              color="accent"
              type="button"
              [disabled]="!!saving"
              (click)="closeExpansionPanel()"
            >
              Cancel
            </button>
            <button
              mat-flat-button
              color="primary"
              class="save-button"
              type="submit"
              [disabled]="!form.valid || !!saving"
            >
              <ng-container
                *ngIf="saving !== 'add' && saving !== 'edit'; else spinner"
              >
                Save
              </ng-container>

              <ng-template #spinner>
                <mat-progress-spinner
                  color="primary"
                  value="100"
                  mode="indeterminate"
                  style="margin-left: auto; margin-right: auto"
                  diameter="20"
                  strokeWidth="2"
                >
                </mat-progress-spinner>
              </ng-template>
            </button>
          </div>
        </mat-action-row>
      </form>
    </mat-expansion-panel>
  </mat-accordion>

  <ng-container *ngFor="let member of event.members; let i = index">
    <mat-card appearance="outlined" *ngIf="i !== editIndex">
      <mat-card-title>
        <div class="desktop">
          <div class="name-container">
            <div
              data-cy="member-name"
              [ngClass]="isAddMemberValid(i) ? '' : 'member-name warn'"
            >
              {{ member.firstName | uppercase }}
              {{ member.lastName | uppercase }}
            </div>
            <div class="paid-container" *ngIf="isPaidFor(member)">
              <div
                class="vertical-line"
                style="margin-left: 10px; margin-right: 10px"
              ></div>
              <div *ngIf="isPaidFor(member)" style="color: #88517c">Paid</div>
              <mat-icon class="dollar-smaller"> monetization_on</mat-icon>
            </div>
          </div>

          <div
            [ngClass]="
              isAddMemberValid(i) ? 'member-buttons' : 'member-buttons warn'
            "
          >
            <button
              mat-stroked-button
              color="accent"
              [disabled]="!!saving"
              (click)="onEdit(member, i)"
            >
              <mat-icon>edit</mat-icon>
              <span>Edit</span>
            </button>
            <button
              [disabled]="isPaidFor(member) || !!saving"
              mat-stroked-button
              color="accent"
              (click)="onDelete(member)"
            >
              <div
                *ngIf="saving !== i; else spinner"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <mat-icon>delete</mat-icon>

                <span>Delete</span>
              </div>

              <ng-template #spinner>
                <mat-progress-spinner
                  color="primary"
                  value="100"
                  mode="indeterminate"
                  style="margin-left: auto; margin-right: auto"
                  diameter="20"
                  strokeWidth="2"
                >
                </mat-progress-spinner>
              </ng-template>
            </button>
          </div>
        </div>
        <div class="mobile">
          <div class="member-section-name">
            <div [ngClass]="isAddMemberValid(i) ? '' : 'member-name warn'">
              {{ member.firstName | uppercase }}
              {{ member.lastName | uppercase }}
            </div>
            <div>
              <small *ngIf="isPaidFor(member)" style="color: #88517c">
                Paid
              </small>
            </div>
          </div>
          <div class="member-section-buttons">
            <button
              mat-icon-button
              [disabled]="!!saving"
              (click)="onEdit(member, i)"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button
              [disabled]="isPaidFor(member) || !!saving"
              mat-icon-button
              (click)="onDelete(member)"
            >
              <ng-container *ngIf="saving !== i; else spinner">
                <mat-icon>delete</mat-icon>
              </ng-container>

              <ng-template #spinner>
                <mat-progress-spinner
                  color="primary"
                  value="100"
                  mode="indeterminate"
                  style="margin-left: auto; margin-right: auto"
                  diameter="20"
                  strokeWidth="2"
                >
                </mat-progress-spinner>
              </ng-template>
            </button>
          </div>
        </div>
      </mat-card-title>
    </mat-card>
  </ng-container>
</div>

<!-- DEALER PORTAL  -->
<div *ngIf="dealerPortal" class="main-event">
  <!-- <h1 class="hide-xs" data-cy="add-members">Add Members</h1> -->
  <div class="grooms-free" *ngIf="event.eventType === 'Wedding'">
    Groom Rents Free! See Details
    <mat-icon
      class="grooms-free-tooltip"
      matTooltip="After 6 orders in the party are paid and shipped, the groom will receive a credit equal to the cost of their rental (up to $250). Fees and shipping not included."
      matTooltipPosition="right"
      >help_outline
    </mat-icon>
  </div>
  <div class="grooms-free" *ngIf="event.eventType === 'Quinceañera'">
    Honor Escort Rents Free! See Details
    <mat-icon
      class="grooms-free-tooltip"
      matTooltip="After 6 orders in the party are paid and shipped, the honor escort will receive a credit equal to the cost of their rental (up to $250). Fees and shipping not included."
      matTooltipPosition="right"
      >help_outline
    </mat-icon>
  </div>
  <div class="event-step-copy">
    <p>Add and organize your party members.</p>
    <p *ngIf="this.hasPaidMember()">
      To delete a paid member, please contact Customer Service at 866-783-5767.
    </p>
  </div>

  <div *ngIf="showWarning$ | async" class="event-step-validator-msg">
    <strong>{{ validatorMsg }}</strong>
  </div>

  <mat-accordion>
    <mat-expansion-panel
      #panel
      hideToggle
      (opened)="openExpansionPanel()"
      (closed)="resetForm()"
      [expanded]="expansionPanelOpen"
    >
      <mat-expansion-panel-header
        data-cy="add-members-header-panel"
        class="mat-expansion-panel-header"
      >
        <mat-panel-title>
          <span *ngIf="editIndex === -1">ADD MEMBER</span>
          <span *ngIf="editIndex !== -1">EDIT MEMBER</span>
          <mat-icon *ngIf="!expansionPanelOpen">add</mat-icon>
          <mat-icon *ngIf="expansionPanelOpen">clear</mat-icon>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="form" #formDirective="ngForm" (ngSubmit)="onSave()">
        <div class="grid">
          <mat-form-field appearance="outline">
            <mat-label>First Name</mat-label>
            <input
              matInput
              formControlName="firstName"
              maxlength="20"
              required
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Last Name</mat-label>
            <input
              matInput
              formControlName="lastName"
              maxlength="20"
              required
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Role</mat-label>
            <!-- Invisble anchor for redirecting focus to role field -->
            <input
              *ngIf="isMobile"
              style="width: 0; height: 0; opacity: 0; position: absolute"
              (focus)="roleFieldFocusById('role')"
            />
            <!-- Invisble anchor for redirecting focus to role field -->
            <mat-select #measselect id="role" formControlName="role">
              <mat-option *ngFor="let role of getRoles()" [value]="role.name">
                {{ role.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <app-phone-form
            [required]="false"
            appearance="outline"
            (formReady)="addChildForm('phone', $event)"
            (valueChange)="setPhone($event)"
          ></app-phone-form>
          <div>
            <mat-form-field appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput formControlName="email" />
              <mat-error *ngIf="getFirstError(form.controls.email) as error">
                {{ error }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <mat-action-row>
          <mat-checkbox *ngIf="displayThisIsMe" formControlName="thisIsMe"
            >This is me</mat-checkbox
          >
          <div *ngIf="!displayThisIsMe">&nbsp;</div>
          <div>
            <button
              mat-stroked-button
              color="accent"
              type="button"
              [disabled]="!!saving"
              (click)="closeExpansionPanel()"
            >
              Cancel
            </button>
            <button
              mat-flat-button
              color="primary"
              class="save-button"
              type="submit"
              [disabled]="!form.valid || !!saving"
            >
              <ng-container
                *ngIf="saving !== 'add' && saving !== 'edit'; else spinner"
              >
                Save
              </ng-container>

              <ng-template #spinner>
                <mat-progress-spinner
                  color="primary"
                  value="100"
                  mode="indeterminate"
                  style="margin-left: auto; margin-right: auto"
                  diameter="20"
                  strokeWidth="2"
                >
                </mat-progress-spinner>
              </ng-template>
            </button>
          </div>
        </mat-action-row>
      </form>
    </mat-expansion-panel>
  </mat-accordion>

  <ng-container *ngFor="let member of event.members; let i = index">
    <mat-card appearance="outlined" *ngIf="i !== editIndex">
      <mat-card-title>
        <div class="desktop">
          <div class="name-container">
            <div
              data-cy="member-name"
              [ngClass]="isAddMemberValid(i) ? '' : 'member-name warn'"
            >
              {{ member.firstName | uppercase }}
              {{ member.lastName | uppercase }}
            </div>
            <div class="paid-container" *ngIf="isPaidFor(member)">
              <div class="vertical-line"></div>
              <div *ngIf="isPaidFor(member)" style="color: #88517c">Paid</div>
              <mat-icon> monetization_on</mat-icon>
            </div>
          </div>

          <div class="member-buttons">
            <button
              mat-stroked-button
              color="accent"
              [disabled]="!!saving"
              (click)="onEdit(member, i)"
            >
              <mat-icon>edit</mat-icon>
              <span>Edit</span>
            </button>
            <button
              [disabled]="isPaidFor(member)"
              mat-stroked-button
              color="accent"
              (click)="onDelete(member)"
            >
              <div
                *ngIf="saving !== i; else spinner"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </div>

              <ng-template #spinner>
                <mat-progress-spinner
                  color="primary"
                  value="100"
                  mode="indeterminate"
                  style="margin-left: auto; margin-right: auto"
                  diameter="20"
                  strokeWidth="2"
                >
                </mat-progress-spinner>
              </ng-template>
            </button>
          </div>
        </div>
        <div class="mobile">
          <div>
            <div [ngClass]="isAddMemberValid(i) ? '' : 'member-name warn'">
              {{ member.firstName | uppercase }}
              {{ member.lastName | uppercase }}
            </div>
            <div>
              <small *ngIf="isPaidFor(member)" style="color: #88517c">
                Paid
              </small>
            </div>
          </div>
          <div>
            <button
              mat-icon-button
              [disabled]="!!saving"
              (click)="onEdit(member, i)"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button
              [disabled]="isPaidFor(member)"
              mat-icon-button
              (click)="onDelete(member)"
            >
              <ng-container *ngIf="saving !== i; else spinner">
                <mat-icon>delete</mat-icon>
              </ng-container>

              <ng-template #spinner>
                <mat-progress-spinner
                  color="primary"
                  value="100"
                  mode="indeterminate"
                  style="margin-left: auto; margin-right: auto"
                  diameter="20"
                  strokeWidth="2"
                >
                </mat-progress-spinner>
              </ng-template>
            </button>
          </div>
        </div>
      </mat-card-title>
    </mat-card>
  </ng-container>
</div>
