<div class="main-event" style="min-height: 425px">
  <div class="grooms-free" *ngIf="event.eventType === 'Wedding'">
    Groom Rents Free! See Details
    <mat-icon
      class="grooms-free-tooltip"
      matTooltip="After 6 orders in the party are paid and shipped, the groom will receive a credit equal to the cost of their rental (up to $250). Fees and shipping not included."
      matTooltipPosition="right"
      >help_outline
    </mat-icon>
  </div>
  <div class="grooms-free" *ngIf="event.eventType === 'Quinceañera'">
    Honor Escort Rents Free! See Details
    <mat-icon
      class="grooms-free-tooltip"
      matTooltip="After 6 orders in the party are paid and shipped, the honor escort will receive a credit equal to the cost of their rental (up to $250). Fees and shipping not included."
      matTooltipPosition="right"
      >help_outline
    </mat-icon>
  </div>

  <div class="header">
    <ng-container>
      <button
        mat-flat-button
        color="primary"
        routerLink="/ensembles/suits-and-tuxedos"
        [queryParams]="{ event: event.id }"
        class="hide-gt-xs"
      >
        Add New Look
      </button>
    </ng-container>
  </div>
  <p *ngIf="event.inStoreInfo">
    Add several looks while comparing. Once you choose your look(s), you're
    ready for next step.
  </p>
  <p *ngIf="!event.inStoreInfo" class="event-step-copy">
    Create your customized looks. Save and review them here before assigning
    them to your party members.
  </p>

  <div *ngIf="showWarning$ | async" class="event-step-validator-msg">
    <strong>{{ validatorMsg }}</strong>
  </div>

  <div class="add-looks-main-wrapper">
    <div class="add-look-container">
      <ng-container>
        <div
          class="add-look-box"
          routerLink="/ensembles/suits-and-tuxedos"
          [queryParams]="{ event: event.id }"
        >
          Add New Look
          <span>+</span>
        </div>
      </ng-container>
    </div>
    <div class="look-carousel-container">
      <div *ngIf="useCarousel()" class="look-card-carousel">
        <app-carousel
          [ariaLabel]="'Carousel of looks'"
          [mobileDisplayCount]="1"
          [desktopDisplayCount]="3"
        >
          <ng-container *ngFor="let look of event.looks">
            <ng-container *appCarouselItem>
              <app-add-looks-card
                [purchasedLooks]="purchasedLooks"
                [event]="event"
                [look]="look"
                [saving]="saving"
                (copyLook)="copyLook($event)"
                (deleteLook)="onDeleteLook($event)"
              ></app-add-looks-card>
            </ng-container>
          </ng-container>
        </app-carousel>
      </div>

      <div
        *ngIf="!useCarousel()"
        class="look-card-grid"
        style="margin-left: 20px"
      >
        <ng-container *ngFor="let look of event.looks">
          <app-add-looks-card
            [purchasedLooks]="purchasedLooks"
            [event]="event"
            [look]="look"
            [saving]="saving"
            (copyLook)="copyLook($event)"
            (deleteLook)="onDeleteLook($event)"
          ></app-add-looks-card>
        </ng-container>
      </div>
    </div>
  </div>
</div>
