<div class="modal-main">
  <div class="close-modal">
    <button mat-icon-button [mat-dialog-close]="true" class="close-button">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="modal-header-container">
    <h2 class="look-name">
      {{ look.title }}
    </h2>
  </div>

  <div class="style-list-container">
    <ng-container *ngFor="let style of orderedStyles">
      <div class="style-entry">
        <span class="style-type">
          {{ getStyleCategoryName(style.styleType) }}
        </span>
        <span class="style-title">
          {{ style.marketingTitle }}
        </span>
      </div>
    </ng-container>
  </div>
</div>
