import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventMember } from 'common-types';

@Component({
  selector: 'app-confirm-delete-member-modal',
  templateUrl: './confirm-delete-member.component.html',
  styleUrls: ['./confirm-delete-member.component.scss'],
})
export class ConfirmDeleteMemberModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public member: EventMember,
    private dialogRef: MatDialogRef<ConfirmDeleteMemberModalComponent>
  ) {}

  confirm(deleteMember: boolean) {
    this.dialogRef.close({
      deleteMember: deleteMember,
    });
  }
}
