import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { orderEcomStyles } from 'business-logic';
import { EcomStyle, Look } from 'common-types';

@Component({
  selector: 'app-look-details-modal',
  templateUrl: './look-details.component.html',
  styleUrls: ['./look-details.component.scss'],
})
export class LookDetailsModalComponent implements OnInit {
  orderedStyles!: EcomStyle[];

  constructor(
    public dialogRef: MatDialogRef<LookDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public look: Look
  ) {}

  ngOnInit(): void {
    this.orderedStyles = orderEcomStyles(this.look.styles);
  }

  getStyleCategoryName(title: string) {
    if (title.toUpperCase() === 'POCKETSQUARE') {
      return 'Pocket Square';
    }

    if (title.toUpperCase() === 'MISCELLANEOUS') {
      return 'Belt';
    }
    return title;
  }
}
