import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EventService } from '@jfw-library/ecommerce/core';
import { getPurchasedLookIds } from 'business-logic';
import { Event, EventMember } from 'common-types';
import { Subscription, firstValueFrom } from 'rxjs';
import { EventManagerService } from '../../services/event-manager-service';
import { ConfirmDeleteLookModalComponent } from '../modals/confirm-delete-look/confirm-delete-look.component';

@Component({
  selector: 'app-add-looks',
  templateUrl: './add-looks.component.html',
  styleUrls: [
    './add-looks.component.scss',
    '../../event-manager.component.scss',
  ],
})
export class AddLooksComponent implements OnInit, AfterViewInit, OnDestroy {
  event!: Event;
  subscription = new Subscription();
  purchasedLooks: Array<string | undefined> = [];
  validatorMsg = '';
  showWarning$ = this.eventManagerService.showWarning$;
  nextClicked = false;
  dealerPortal = this.environment.dealerPortal;
  saving = false;
  isMobile = false;

  constructor(
    @Inject('environment') private environment: any,
    private eventService: EventService,
    private eventManagerService: EventManagerService,
    private cdr: ChangeDetectorRef,
    private matDialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.eventService.selectedEvent$.subscribe({
        next: (event) => {
          this.event = event;
          this.purchasedLooks = getPurchasedLookIds(event);
        },
      })
    );

    this.subscription.add(
      this.eventManagerService.results$.subscribe((validator) => {
        if (validator) {
          this.validatorMsg = validator.msg;
          // this.cdr.detectChanges();
        }
      })
    );

    this.subscription.add(
      this.breakpointObserver.observe(Breakpoints.XSmall).subscribe({
        next: (result) => (this.isMobile = result.matches),
      })
    );
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  useCarousel(): boolean {
    return this.event.looks.length > 3 || this.isMobile;
  }

  async copyLook(lookId: string): Promise<void> {
    this.saving = true;

    try {
      const result = await this.eventManagerService.copyLook(
        this.event.id,
        lookId
      );
      console.log('copyLook result: ', result);

      const { updatedEvent } = result;

      this.eventService.setSelectedEventWithEvent(updatedEvent, 'AddLooksComponent - copyLook');

      console.log('Look copied successfully');
      this.saving = false;
      return;
    } catch (error) {
      console.error('Error copying look: ', error);
      alert('There was an error copying the look.  Please try again.');
      this.saving = false;
      return;
    }
  }

  private async deleteLook(lookId: string): Promise<void> {
    this.saving = true;
    try {
      const result = await this.eventManagerService.deleteLook(
        this.event.id,
        lookId
      );
      console.log('Look deleted successfully');
      const { updatedEvent } = result;
      this.eventService.setSelectedEventWithEvent(updatedEvent, 'AddLooksComponent - deleteLook');
      this.saving = false;
      return;
    } catch (error) {
      console.error('Error deleting look: ', error);
      alert('Error deleting look. Please try again.');
      this.saving = false;
      return;
    }
  }

  async onDeleteLook(lookId: string | undefined) {
    if (lookId === undefined) {
      return;
    }
    if (this.event?.looks) {
      if (!this.isLookAssignedToMember(lookId)) {
        // if look is not assigned to any member, delete it without confirmation
        return this.deleteLook(lookId);
      } else {
        // if look is assigned to member, show confirmation dialog
        const { deleteLook } = await firstValueFrom(
          this.matDialog
            .open(ConfirmDeleteLookModalComponent, {
              maxWidth: '330px',
            })
            .afterClosed()
        );
        if (deleteLook) {
          return this.deleteLook(lookId);
        }
      }
    }
  }

  getMembersAssignedToLook(lookId: string): Array<EventMember> {
    return this.event.members.filter((member: EventMember) => {
      return member.memberLook?.id === lookId;
    });
  }

  isLookAssignedToMember(lookId: string): boolean {
    return this.getMembersAssignedToLook(lookId).length > 0;
  }
}
