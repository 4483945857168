<div *ngIf="!hideStepperVal()" class="scroller">
  <mat-stepper
    #stepper
    linear
    [orientation]="(stepperOrientation$ | async)!"
    selectedIndex="{{ selectedIndex }}"
    (selectionChange)="onChange($event)"
  >
    <ng-container *ngFor="let eventStep of eventSteps(); let i = index">
      <mat-step
        *ngIf="eventStep.label"
        [editable]="!isSaving"
        [completed]="getStepCompleted(i)"
        [label]="eventSteps()[i].label ?? ''"
        [color]="getIconColor(i)"
      >
        <ng-template matStepLabel>
          <span [ngClass]="getTextColor(i)">{{
            eventStep.label | uppercase
          }}</span>
        </ng-template>
      </mat-step>
      <!-- [style.color]="getTextColor(i) -->
    </ng-container>
    <!-- <ng-template matStepperIcon="valid">
      <mat-icon>check</mat-icon>
    </ng-template>

    <ng-template matStepperIcon="invalid">
      <mat-icon>priority_high</mat-icon>
    </ng-template> -->
  </mat-stepper>
</div>
