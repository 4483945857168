import { SelectionModel } from '@angular/cdk/collections';
import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  computed,
  inject,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthService,
  EventService,
  ScrollService,
} from '@jfw-library/ecommerce/core';
import { ValidationService } from '@jfw-library/ecommerce/shared';
import {
  eventMemberMeasurementsComplete,
  getIndividualView,
  getLookFromMemberLook,
  getMemberView,
  isUserOrganizerOrAdminForEvent,
  setLastVisitedPage,
} from 'business-logic';
import { EventMember } from 'common-types';
import dayjs from 'dayjs';
import { EventManagerService } from '../../services/event-manager-service';
import { ShippingDetailsModalComponent } from '../modals/shipping-details/shipping-details.component';

@Component({
  selector: 'app-track-progress',
  templateUrl: './track-progress.component.html',
  styleUrls: [
    './track-progress.component.scss',
    '../../event-manager.component.scss',
  ],
})
export class TrackProgressComponent implements OnInit {
  @ViewChild('manageSection') private manageSection!: ElementRef;

  user = toSignal(this.authService.user$);

  event = toSignal(this.eventService.selectedEvent$);
  members = computed(() => this.event()?.members ?? []);
  isAdminOrOrganizer = computed(() =>
    isUserOrganizerOrAdminForEvent(this.event(), this.user()),
  );
  isIndividual = computed(() => {
    const event = this.event();
    if (!event) return false;
    return getIndividualView(event);
  });
  looks = computed(() => this.event()?.looks);
  individualMemberLook = computed(() => {
    const event = this.event();
    return this.isIndividual() ? event?.members[0]?.memberLook : undefined;
  });
  individualLook = computed(() => {
    const looks = this.looks();
    const memberLook = this.individualMemberLook();
    if (!looks || !memberLook) return undefined;
    return getLookFromMemberLook(memberLook, looks);
  });
  isMemberView = computed(() => getMemberView(this.event(), this.user()));

  membersAvailable = computed(() => this.members().length > 0);

  queryParams = toSignal(this.route.queryParams);
  altNow = computed(() => this.queryParams()?.altNow);

  readonly eventIsNearMonthsAccepted: number = 2;
  readonly eventIsNearMonthsMeasurements: number = 1;
  readonly eventIsNearMonthsPaid: number = 1;
  currentDate = computed(() => {
    const altNow = this.altNow();
    let currentDate = dayjs(Date.now());
    if (altNow !== undefined) {
      let date = new Date(
        altNow.slice(4, 8),
        altNow.slice(0, 2) - 1,
        altNow.slice(2, 4),
      );
      currentDate = dayjs(date);
    }
    return currentDate;
  });
  eventIsNearAccepted = computed(() => {
    const event = this.event();
    if (!event) return false;
    const currentDate = this.currentDate();
    const eventDate = event.eventDate * 1000;
    const cutOffDateAccepted = dayjs(eventDate).subtract(
      this.eventIsNearMonthsAccepted,
      'month',
    );
    return currentDate >= cutOffDateAccepted;
  });
  eventIsNearMeasurements = computed(() => {
    const event = this.event();
    if (!event) return false;
    const currentDate = this.currentDate();
    const eventDate = event.eventDate * 1000;
    const cutOffDateMeasurements = dayjs(eventDate).subtract(
      this.eventIsNearMonthsMeasurements,
      'month',
    );
    return currentDate >= cutOffDateMeasurements;
  });
  eventIsNearPaid = computed(() => {
    const event = this.event();
    if (!event) return false;
    const currentDate = this.currentDate();
    const eventDate = event.eventDate * 1000;
    const cutOffDatePaid = dayjs(eventDate).subtract(
      this.eventIsNearMonthsPaid,
      'month',
    );
    return currentDate >= cutOffDatePaid;
  });

  selection = new SelectionModel<EventMember>(true, []);

  eventDateValidator = computed(() => {
    const event = this.event();
    if (!event) return { valid: false, msg: 'Event not found' };
    return this.validationService.isEventDateLocked(event);
  });

  isEventDateLocked = computed(() => this.eventDateValidator().valid !== true);
  isEventDateLockedMsg = computed(() => this.eventDateValidator()?.msg ?? '');
  expandedMobileMembersPanel: Array<boolean> = [];

  tableColumns = computed(() => {
    const columns = [
      'memberName',
      'inviteSent',
      'inviteAccepted',
      'measurements',
      'paid',
      'shipped',
      'email',
    ];
    const isIndividual = this.isIndividual();
    const isAdminOrOrganizer = this.isAdminOrOrganizer();
    const memberViewColumns = [0, 3, 4, 5];
    const singleUserViewColumns = [0, 3, 4, 5];
    if (!isAdminOrOrganizer && !isIndividual) {
      return columns.filter((col, idx) => memberViewColumns.includes(idx));
    } else if (isIndividual) {
      return columns.filter((col, idx) => singleUserViewColumns.includes(idx));
    }
    return columns;
  });

  showScrollToTop = signal(false);

  invitesResent = signal(false);
  errorSending = signal(false);
  sendingInvites = signal(false);
  private isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  constructor(
    private eventService: EventService,
    private eventManagerService: EventManagerService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private validationService: ValidationService,
    private authService: AuthService,
    private router: Router,
    private scrollService: ScrollService,
  ) {}

  ngOnInit(): void {
    setLastVisitedPage('/track-progress');
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    const manageSectionHeight = this.manageSection.nativeElement.offsetHeight;
    if (
      manageSectionHeight !== 0 &&
      window.pageYOffset > 5 * manageSectionHeight
    ) {
      this.showScrollToTop.set(true);
    } else {
      this.showScrollToTop.set(false);
    }
  }

  scrollToTop(): void {
    // window.scrollTo({ top: 0, behavior: 'smooth' });
    this.scrollService.scrollToTop();
  }

  routeToMeasurements() {
    const event = this.event();
    if (!event) return;
    this.router.navigate(['/event', event.id, 'review-measurements']);
  }

  async sendSelectedInvites() {
    const event = this.event();
    if (!event) return;
    this.errorSending.set(false);
    this.sendingInvites.set(true);
    this.invitesResent.set(false);
    const results = await Promise.allSettled(
      this.selection.selected.map(async (member, index, array) => {
        try {
          if (!member?.email) {
            throw new Error(
              'Member email is undefined.  Unable to send invite.',
            );
          }
          const result = await this.eventManagerService.sendMemberInvite(
            event,
            member,
          );
          return result;
        } catch (error) {
          console.error('Failed to send invite email', error);
          throw error;
        }
      }),
    );

    results.forEach((result, index) => {
      if (result.status === 'fulfilled') {
        this.selection.deselect(this.selection.selected[index]);
      } else {
        this.errorSending.set(true);
      }
    });

    this.invitesResent.set(true);
    this.sendingInvites.set(false);
  }

  selectInviteToggle($event: any, selection: any, member: EventMember) {
    this.invitesResent.set(false);
    if (!this.sendingInvites()) {
      this.errorSending.set(false);
      selection.toggle(member);
    }
  }

  scrollToView() {
    if (!this.isBrowser) return;
    const yOffset = -100;
    const y =
      this.manageSection.nativeElement.getBoundingClientRect().top +
      window.pageYOffset +
      yOffset;
    // window.scrollTo({ top: y, behavior: 'smooth' });
    this.scrollService.scrollToPosition([0, y]);
  }

  measurementsCompleted(member: EventMember): boolean {
    return eventMemberMeasurementsComplete(member);
  }

  openShippingModal(member: EventMember) {
    const event = this.event();
    if (!event) return;
    this.dialog.open(ShippingDetailsModalComponent, {
      minWidth: '300px',
      data: { ...member, eventDate: event.eventDate },
      panelClass: 'dialog-modal',
      autoFocus: false,
    });
  }

  setExpandedMobilePanels(): void {
    this.event()?.members.forEach((member, idx) => {
      this.expandedMobileMembersPanel[idx] = false;
    });
  }

  toggleExpandedMobilePanels(idx: number): void {
    this.expandedMobileMembersPanel[idx] =
      !this.expandedMobileMembersPanel[idx];
  }
}
