import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Event, Look } from 'common-types';

@Component({
  selector: 'app-add-looks-card',
  templateUrl: './add-looks.component.html',
  styleUrls: [
    './add-looks.component.scss',
    '../../../event-manager.component.scss',
  ],
})
export class AddLooksCardComponent {
  @Input() event!: Event;
  @Input() look!: Look;
  @Input() purchasedLooks!: Array<string | undefined>;
  @Input() saving = false;
  @Output() copyLook = new EventEmitter<string>();
  @Output() deleteLook = new EventEmitter<string>();

  disabledLookText = 'Cannot modify purchased looks';

  constructor() {}

  canModifyLook(lookId: string): boolean {
    if (this.purchasedLooks) {
      return this.purchasedLooks.includes(lookId);
    }
    return false;
  }

  onCopy(lookId: string): void {
    if (this.saving) return;
    this.copyLook.emit(lookId);
  }

  onDelete(lookId: string): void {
    if (this.saving) return;
    this.deleteLook.emit(lookId);
  }
}
