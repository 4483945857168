import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-delete-look-modal',
  templateUrl: './confirm-delete-look.component.html',
  styleUrls: ['./confirm-delete-look.component.scss'],
})
export class ConfirmDeleteLookModalComponent {
  constructor(
    private dialogRef: MatDialogRef<ConfirmDeleteLookModalComponent>
  ) {}

  confirm(deleteLook: boolean) {
    this.dialogRef.close({
      deleteLook: deleteLook,
    });
  }
}
