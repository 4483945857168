import { Component, Inject, inject, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EventService } from '@jfw-library/ecommerce/core';
import { StoreService } from '@jfw-library/ecommerce/shared';
import { getSelectedStoreFromLocalStorage } from 'business-logic';
import { Event } from 'common-types';
import { firstValueFrom } from 'rxjs';
import { EventManagerService } from '../../../services/event-manager-service';

export type GroupIndividualConfirmChangeData = {
  event: Event;
  action: 'switch-to-individual' | 'switch-to-group';
};


@Component({
  selector: 'app-group-individual-confirm-change-modal',
  templateUrl: './group-individual-confirm-change.component.html',
  styleUrls: ['./group-individual-confirm-change.component.scss'],
})
export class GroupIndividualConfirmChangeModalComponent {
  eventChangeData: GroupIndividualConfirmChangeData;
  action: 'switch-to-individual' | 'switch-to-group' | undefined;
  event: Event | undefined;
  updatingEvent = signal(false);

  private eventManagerService = inject(EventManagerService);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GroupIndividualConfirmChangeData,
    private dialogRef: MatDialogRef<GroupIndividualConfirmChangeModalComponent>,
    private eventService: EventService,
    private router: Router,
    private storeService: StoreService
  ) {
    this.eventChangeData = data;
    this.action = data.action;
    this.event = data.event;
  }

  async confirmChange() {
    if (this.event === undefined) {
      throw new Error('Event is undefined');
    }

    console.log(this.eventChangeData);
    if (this.action === 'switch-to-individual') {
      return this.switchToIndividual(this.event);
    }

    if (this.action === 'switch-to-group') {
      return this.switchToGroup(this.event);
    }
  }

  async switchToIndividual(event: Event) {
    this.updatingEvent.set(true);
    // await firstValueFrom(of(true).pipe(delay(3000), map(() => { })));
    // const updatedEvent = event;

    try {
      if (event === undefined) {
        throw new Error('Event is undefined');
      }

      //   //this.cartService.clearCartCache(); // don't need this anymore
      let refreshAfterNavigate = false;

      const { updatedEvent } =
        await this.eventManagerService.switchToIndividual(event.id);
      try {
        await this.eventService.setSelectedEventWithEvent(updatedEvent);
      } catch (error) {
        console.error(
          'switchGroupToIndividual was successful, but there was an error setting selected event with event',
          error
        );
        refreshAfterNavigate = true;
      }

      const componentRoute = updatedEvent.isSingleUser ? 'select-look' : 'add-looks';
      await this.router.navigate(['event', updatedEvent.id, componentRoute]);
      if (refreshAfterNavigate) {
        // if there was an error setting the selected event with event, reload the page
        return window.location.reload();
      }

      return this.closeDialog(true);
    } catch (error) {
      console.error('Error in switchToOrderOnline', error);
      alert(
        'There was an error switching your event to order online.  Please try again or contact Customer Service.'
      );
      return this.closeDialog(false);
    }

  }


  async switchToGroup(event: Event): Promise<void> {
    this.updatingEvent.set(true);
    // await firstValueFrom(of(true).pipe(delay(3000), map(() => { })));
    // const updatedEvent = event;

    try {
      if (event === undefined) {
        throw new Error('Event is undefined');
      }

      //   //this.cartService.clearCartCache(); // don't need this anymore
      let refreshAfterNavigate = false;

      const lsStore = getSelectedStoreFromLocalStorage();
      const data = {
        ...(lsStore ? { store: lsStore } : undefined)
      };

      const { updatedEvent } =
        await this.eventManagerService.switchToGroup(event.id);
      try {
        await this.eventService.setSelectedEventWithEvent(updatedEvent);
      } catch (error) {
        console.error(
          'switchToOrderInStore was successful, but there was an error setting selected event with event',
          error
        );
        refreshAfterNavigate = true;
      }

      const componentRoute = updatedEvent.isSingleUser ? 'select-look' : 'add-looks';
      await this.router.navigate(['event', updatedEvent.id, componentRoute]);
      if (refreshAfterNavigate) {
        // if there was an error setting the selected event with event, reload the page
        return window.location.reload();
      }

      return this.closeDialog(true);
    } catch (error) {
      console.error('Error in switchToOrderOnline', error);
      alert(
        'There was an error switching your event to order online.  Please try again or contact Customer Service.'
      );
      return this.closeDialog(false);
    }


  }

  async closeDialog(success: boolean) {
    this.dialogRef.close({ success });
    await firstValueFrom(this.dialogRef.afterClosed());
    console.log('dialog closed');
    this.updatingEvent.set(false);
  }
}
