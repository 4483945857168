import {
  ChangeDetectorRef,
  Component,
  computed,
  inject,
  Inject,
  Signal,
  signal,
  ViewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { AuthService, EventService } from '@jfw-library/ecommerce/core';
import { ValidationService } from '@jfw-library/ecommerce/shared';
import { getEventSteps } from 'business-logic';
import { EventStep } from 'common-types';
import { EventStepperComponent } from './components/event-stepper/event-stepper.component';
import { EventManagerService } from './services/event-manager-service';

@Component({
  selector: 'app-event-manager',
  templateUrl: './event-manager.component.html',
  styleUrls: ['./event-manager.component.scss'],
})
export class EventManagerComponent {
  @ViewChild('stepper') stepper!: EventStepperComponent;
  private authService = inject(AuthService);
  private user = toSignal(this.authService.user$);
  event = toSignal(this.eventService.selectedEvent$);
  hideStepper = signal(false);
  eventSteps = computed(() => {
    const event = this.event();
    if (!event) return [];
    const user = this.user();
    const eventSteps = getEventSteps(
      event,
      this.environment.dealerPortal,
      user,
    );
    console.log('eventSteps in EventManager:', eventSteps);
    return eventSteps;
  });
  eventStep = signal<EventStep | undefined>(undefined);
  isLastStepBeforeCheckoutOrShare = computed(() => {
    const eventStep = this.eventStep();
    if (!eventStep) return false;
    const eventSteps = this.eventSteps();
    const secondToLastStep = eventSteps[eventSteps.length - 2];
    return eventStep.route === secondToLastStep.route;
  });
  isSaving = toSignal(this.eventManagerService.isSaving$);
  shareEventDetailsFormValid = toSignal(
    this.eventManagerService.shareEventDetailsFormValid$,
  );
  currentStepRoute = toSignal(this.eventManagerService.currentChildRoute$, {
    initialValue: '',
  });
  nextDisabled = computed(() => {
    const nextDisabled =
      this.isSaving() ||
      this.shareEventDetailsFormValid() === false ||
      this.showEventLockMsg();
    console.log('nextDisabled:', nextDisabled);
    return nextDisabled;
  });
  showNextSpinner = computed(
    () => this.isSaving() && this.clickDirection() === 'next',
  );
  previousDisabled = computed(() => this.isSaving() ?? false);
  showPreviousSpinner = computed(
    () => this.isSaving() && this.clickDirection() === 'prev',
  );

  dateLockValidatorMsg = computed(() => {
    const event = this.event();

    if (!event) {
      console.log('event is undefined');
      return '';
    }
    const dateLockValidator = this.validationService.isEventDateLocked(event);
    console.log('dateLockValidator:', dateLockValidator);
    if (!dateLockValidator.valid) {
      return dateLockValidator.msg;
    } else {
      return '';
    }
  });
  showEventLockMsg = computed(() => {
    const show =
      !!this.dateLockValidatorMsg() && this.isLastStepBeforeCheckoutOrShare();
    console.log('showEventLockMsg:', show);
    return show;
  });

  clickDirection: Signal<'next' | 'prev' | undefined> = toSignal(
    this.eventManagerService.clickDirection$,
  );
  showWarning = toSignal(this.eventManagerService.showWarning$, {
    initialValue: false,
  });

  canProceed = toSignal(this.eventService.canProceed$);

  constructor(
    @Inject('environment') private environment: any,
    private eventService: EventService,
    private eventManagerService: EventManagerService,
    private validationService: ValidationService,
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) {}

  setHideStepper(hideStepper: boolean): void {
    this.hideStepper.set(hideStepper);
  }

  setEventStep(eventStep: EventStep): void {
    console.log('setEventStep', eventStep);
    this.eventStep.set(eventStep);
    const segments = this.router.url.split('/');
    const childRoute = segments[segments.length - 1].toLowerCase();
    console.log('childRoute:', childRoute);

    // Manually setting label for specific use-cases (if applicable)
    if (eventStep.label === undefined || eventStep.label === '') {
      switch (childRoute) {
        case 'track-progress':
          eventStep.label = 'Track Progress';
          break;
        default:
          break;
      }
    }
  }

  onPrev(): void {
    this.eventManagerService.previousClicked();
  }

  onNext(): void {
    this.eventManagerService.nextClicked();
  }

  // onCanProceed(canProceed: boolean): void {
  //   // this.canProceed = canProceed;
  //   if (this.nextClicked) {
  //     if (this.canProceed()) {
  //       this.stepper.onNext();
  //       this.showWarning = false;
  //     } else {
  //       this.showWarning = true;
  //     }
  //     this.nextClicked = false;
  //   } else {
  //     if (this.canProceed()) {
  //       this.showWarning = false;
  //     }
  //   }
  // }
}
