<div class="modal-main">
  <div class="close-modal">
    <!-- No Button -->
  </div>

  <div class="modal-header-container">
    <h3>Confirm Change</h3>
  </div>


  @if(updatingEvent()) {
    <mat-dialog-content>
      <div class="container">
        <h4>Updating Event</h4>
        <mat-progress-spinner
          color="primary"
          mode="indeterminate"
          diameter="100"
          strokeWidth="7"
        >
        </mat-progress-spinner>
      </div>
    </mat-dialog-content>
  }

  @else {
    <mat-dialog-content>
    @switch(action)
    {
      @case('switch-retailer'){
        <p>Are you sure you want to switch your selected store?</p>
      }
      @case('order-online'){
      <p>Are you sure you want to change your event to order <strong>online</strong>?</p>
      }
      @case('order-inStore') {
      <p>Are you sure you want to change your event to order <strong>in-store</strong>?</p>
      }
      @default {
      <p>Are you sure you want to change your event?</p>
      }
    }
    </mat-dialog-content>

    <mat-dialog-actions align="center">

      <button
        mat-flat-button
        color="primary"
        style="margin-right: 20px; min-width: 100px;"
        (click)="confirmChange()"
      >
        Yes
      </button>
      <button
        mat-stroked-button
        color="accent"
        (click)="closeDialog(false)"
        style="min-width:100px;"
      >
        No
      </button>
    </mat-dialog-actions>
  }
</div>
