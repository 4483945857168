import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-update-error-modal',
  templateUrl: './update-error.component.html',
  styleUrls: ['./update-error.component.scss'],
})
export class UpdateErrorModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<UpdateErrorModalComponent>
  ) {}

  close() {
    this.dialogRef.close();
  }
}
