import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, computed, effect } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { AuthService, EventService } from '@jfw-library/ecommerce/core';
import { StoreInfoModalComponent } from '@jfw-library/ecommerce/shared';
import { canEventBeCreatedOnline } from 'business-logic';
import {
  Event,
  EventMember,
  InStoreEventConfirmChangeData,
  Look,
} from 'common-types';
import { map } from 'rxjs';
import { EventManagerService } from '../../services/event-manager-service';
import { InStoreConfirmChangeModalComponent } from '../modals/in-store-confirm-change/in-store-confirm-change.component';

@Component({
  selector: 'app-in-store-summary',
  templateUrl: './in-store-summary.component.html',
  styleUrls: [
    './in-store-summary.component.scss',
    '../../event-manager.component.scss',
  ],
})
export class InStoreSummaryComponent {
  user = toSignal(this.authService.user$);
  event = toSignal<Event>(this.eventService.selectedEvent$);
  isSaving = toSignal(this.eventManagerService.isSaving$);
  showThankYou = toSignal(this.eventManagerService.displayThankYou$);
  store = computed(() => this.event()?.inStoreInfo?.store);
  looks = computed(() => this.event()?.looks ?? []);
  members = computed(() => this.event()?.members ?? []);
  map = new Map<Look, EventMember[]>();
  eventCanBeOnline = computed(() => {
    const { eventDate } = this.event() ?? {};
    return eventDate ? canEventBeCreatedOnline(eventDate) : false;
  });

  dataSharedWithStore = computed(
    () => this.event()?.inStoreInfo?.dateSharedWithStore !== undefined,
  );
  displayThanksCopy = computed(
    () => this.dataSharedWithStore() && this.showThankYou(),
  );
  useCarousel = computed(
    () => (this.event()?.members.length ?? 0) > 3 || this.isMobile,
  );
  isMobile = toSignal(
    this.breakpointObserver
      .observe(Breakpoints.XSmall)
      .pipe(map((result) => result.matches)),
  );

  routeToFirstStep = effect(() => {
    if (this.event()?.inStoreInfo?.store === undefined) {
      this.eventService.routeToFirstStep();
    }
  });

  constructor(
    private authService: AuthService,
    public dialog: MatDialog,
    private eventService: EventService,
    private eventManagerService: EventManagerService,
    private breakpointObserver: BreakpointObserver,
  ) {}

  ngOnInit(): void {
    this.setMap();
  }

  setMap(): void {
    this.map.clear();
    const user = this.user();
    const userId = user?.uid ?? '';
    for (let look of this.looks()) {
      let membersWithThisLook: EventMember[] = [];
      let foundUser: boolean = false;
      for (let member of this.members()) {
        if (member.memberLook?.id === look.id) {
          membersWithThisLook.push(member);
          if (member.userId === userId) {
            foundUser = true;
          }
        }
      }
      if (membersWithThisLook.length > 0) {
        this.map.set(look, membersWithThisLook);
      }
    }
  }

  storeDetails(): void {
    if (this.store() === undefined) {
      console.log('store is undefined');
      return;
    }
    this.dialog.open(StoreInfoModalComponent, {
      data: this.store(),
      width: '300px',
      panelClass: 'app-full-bleed-dialog',
      maxWidth: '90%',
      maxHeight: '90%',
      autoFocus: false,
    });
  }

  async switchRetailer() {
    const event = this.event();
    if (event !== undefined) {
      const inStoreEventChange: InStoreEventConfirmChangeData = {
        action: 'switch-retailer',
        event,
      };

      this.dialog.open(InStoreConfirmChangeModalComponent, {
        width: '300px',
        minWidth: '300px',
        minHeight: '300px',
        data: inStoreEventChange,
      });
    }
  }

  async orderOnline() {
    const event = this.event();
    if (event !== undefined) {
      const inStoreEventChange: InStoreEventConfirmChangeData = {
        action: 'order-online',
        event,
      };

      this.dialog.open(InStoreConfirmChangeModalComponent, {
        width: '300px',
        minWidth: '300px',
        minHeight: '300px',
        data: inStoreEventChange,
      });
    }
  }
}
