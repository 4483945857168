<div class="in-store-summary">
  <h1 class="in-store-summary-header">EVENT DETAILS</h1>
  <div class="thank-you-copy" *ngIf="displayThanksCopy()">
    <h1 class="thank-you-header">THANK YOU!</h1>
    <p>
      We received your event information. You'll receive a confirmation email
      shortly. Please visit or call your selected Jim's Formal Wear Retailer to
      complete the ordering process.
    </p>
  </div>
</div>

<ng-container *ngIf="!isSaving()">
  <div class="timeline">
    <h3>EVENT TIMELINE</h3>
    <app-event-timeline [event]="event()"></app-event-timeline>
  </div>

  <div class="event-info">
    <div>
      <h3 class="retailer-header">RETAILER INFORMATION</h3>

      <div class="store">
        <p>
          <a class="store-name" (click)="storeDetails()" disableRipple>{{
            store()?.aname
          }}</a>
        </p>
        <p>{{ store()?.address1 }}</p>
        <p>{{ store()?.address2 }}</p>
        <p>
          {{ store()?.city }}, {{ store()?.state | uppercase }}
          {{ store()?.zipCode }}
        </p>
        <p>{{ store()?.phone }}</p>
        @if (store()?.distance) {
          <p>{{ store()?.distance | number: "1.1-2" }} miles</p>
        }
        <p>
          <a class="store-info-ref" (click)="storeDetails()">Store Info</a>
        </p>
      </div>
    </div>
    <!--
    <div *ngIf="!displayThanksCopy()" class="change-event">
      <h3>NEED TO CHANGE YOUR EVENT?</h3>
      <div class="change-event-buttons">
        <button mat-flat-button color="primary" (click)="switchRetailer()">
          SWITCH RETAILER
        </button>
        <button
          [title]="
            !eventCanBeOnline
              ? 'Online ordering is unavailable for events less than two weeks from time of ordering.'
              : ''
          "
          [disabled]="!eventCanBeOnline"
          mat-flat-button
          color="primary"
          (click)="orderOnline()"
        >
          ORDER ONLINE
        </button>
      </div>
      <p *ngIf="!eventCanBeOnline">
        Online ordering is unavailable for events less than two weeks from time
        of ordering.
      </p>
    </div>
    -->
  </div>

  <div *ngIf="looks().length" class="looks">
    <div *ngIf="looks().length === 0">
      <h3>YOUR LOOKS</h3>
      <p>No looks submitted</p>
    </div>
    <div *ngIf="looks().length === 1">
      <h3>YOUR LOOKS</h3>
      <p>Please contact your selected retailer to make updates to your look</p>
    </div>
    <div *ngIf="looks().length > 1">
      <h3>YOUR LOOKS</h3>
      <p>
        Please contact your selected retailer to make updates to your look(s)
      </p>
    </div>

    <div *ngIf="!useCarousel() && looks().length > 0" class="look-card-grid">
      <ng-container *ngFor="let look of looks()">
        <app-review-event-look-card [look]="look"></app-review-event-look-card>
      </ng-container>
    </div>

    <div *ngIf="useCarousel()" class="look-card-carousel">
      <app-carousel
        [ariaLabel]="'Carousel of looks'"
        [mobileDisplayCount]="1"
        [desktopDisplayCount]="3"
      >
        <ng-container *ngFor="let entry of map | keyvalue">
          <ng-container *appCarouselItem>
            <app-review-event-look-card
              [look]="entry.key"
              [members]="entry.value"
            ></app-review-event-look-card>
          </ng-container>
        </ng-container>
      </app-carousel>
    </div>
  </div>
</ng-container>
