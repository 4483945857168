import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CarouselModule } from '@jfw-library/ecommerce/common-components/carousel';
import { EcommerceSharedModule } from '@jfw-library/ecommerce/shared';
import { AddLooksComponent } from './components/add-looks/add-looks.component';
import { AddMembersComponent } from './components/add-members/add-members.component';
import { AssignLooksComponent } from './components/assign-looks/assign-looks.component';
import { ChooseStoreComponent } from './components/choose-store/choose-store.component';
import { EcomEventDetailsComponent } from './components/ecom-event-details/ecom-event-details.component';
import { EventDetailsComponent } from './components/event-details/event-details.component';
import { EventStepperComponent } from './components/event-stepper/event-stepper.component';
import { InStoreSummaryComponent } from './components/in-store-summary/in-store-summary.component';
import { AddLooksCardComponent } from './components/look-cards/add-looks/add-looks.component';
import { AssignLooksCardComponent } from './components/look-cards/assign-looks/assign-looks.component';
import { ReviewEventLookCardComponent } from './components/look-cards/review-event/review-event.component';
import { SelectLookCardComponent } from './components/look-cards/select-look/select-look.component';
import { ShareEventLookCardComponent } from './components/look-cards/share-event/share-event-look-card.component';
import { AssignLooksImageModalComponent } from './components/modals/assign-looks-image/assign-looks-image.component';
import { ConfirmDeleteLookModalComponent } from './components/modals/confirm-delete-look/confirm-delete-look.component';
import { ConfirmDeleteMemberModalComponent } from './components/modals/confirm-delete-member/confirm-delete-member.component';
import { ConfirmSaveEcomEventDetailsModalComponent } from './components/modals/confirm-save-ecom-event-details/confirm-save-ecom-event-details';
import { GroupIndividualConfirmChangeModalComponent } from './components/modals/group-individual-confirm-change/group-individual-confirm-change.component';
import { InStoreConfirmChangeModalComponent } from './components/modals/in-store-confirm-change/in-store-confirm-change.component';
import { LookDetailsModalComponent } from './components/modals/look-details/look-details.component';
import { OrganizerInfoModalComponent } from './components/modals/organizer-info/organizer-info.component';
import { ReviewEventNewMembers } from './components/modals/review-event-new-members/review-event-new-members.component';
import { ShippingDetailsModalComponent } from './components/modals/shipping-details/shipping-details.component';
import { UpdateErrorModalComponent } from './components/modals/update-error/update-error.component';
import { ReviewEventComponent } from './components/review-event/review-event.component';
import { ReviewMeasurementsComponent } from './components/review-measurements/review-measurements.component';
import { SelectLookComponent } from './components/select-look/select-look.component';
import { ShareEventDetailsComponent } from './components/share-event-details/share-event-details.component';
import { TrackProgressComponent } from './components/track-progress/track-progress.component';
import { TransferConfirmationComponent } from './components/transfer-confirmation/transfer-confirmation.component';
import { WearersInfoComponent } from './components/wearers-info/wearers-info.component';
import { EventManagerRoutingModule } from './event-manager-routing.module';
import { EventManagerComponent } from './event-manager.component';
import { EventManagerEnvironmentService } from './services/event-manager-environment.service';
import { EVENT_MANAGER_ENVIRONMENT } from './tokens/event-manager-environment.token';

@NgModule({
  declarations: [
    AddLooksCardComponent,
    AddLooksComponent,
    AddMembersComponent,
    AssignLooksCardComponent,
    AssignLooksComponent,
    AssignLooksImageModalComponent,
    ChooseStoreComponent,
    ConfirmDeleteLookModalComponent,
    ConfirmDeleteMemberModalComponent,
    ConfirmSaveEcomEventDetailsModalComponent,
    EcomEventDetailsComponent,
    EventDetailsComponent,
    EventDetailsComponent,
    EventManagerComponent,
    EventStepperComponent,
    GroupIndividualConfirmChangeModalComponent,
    InStoreConfirmChangeModalComponent,
    InStoreSummaryComponent,
    LookDetailsModalComponent,
    OrganizerInfoModalComponent,
    ReviewEventComponent,
    ReviewEventLookCardComponent,
    ReviewEventNewMembers,
    ReviewMeasurementsComponent,
    SelectLookCardComponent,
    SelectLookComponent,
    ShareEventDetailsComponent,
    ShareEventLookCardComponent,
    ShippingDetailsModalComponent,
    TrackProgressComponent,
    TransferConfirmationComponent,
    UpdateErrorModalComponent,
    WearersInfoComponent,
  ],
  imports: [
    CarouselModule,
    CommonModule,
    EcommerceSharedModule,
    EventManagerRoutingModule,
    LayoutModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSelectModule,
    MatStepperModule,
    MatStepperModule,
    MatTableModule,
    MatTooltipModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: EVENT_MANAGER_ENVIRONMENT,
      useFactory: (environmentService: EventManagerEnvironmentService) =>
        environmentService.getEnvironment(),
      deps: [EventManagerEnvironmentService],
    },
  ],
})
export class EventManagerModule {}
