<div class="look-card-container">
  <div class="look-card" (click)="onSelect(look)">
    <div class="look-card-name">
      <strong tabIndex="0">{{ look.title }}</strong>
    </div>

    <div class="look-card-image">
      <app-look-mannequin
        [styles]="look.styles"
        [canSelect]="true"
        [saving]="saving"
        [selected]="isSelected(look)"
        [canEnlarge]="isMobile"
        [required]="cardRequired()"
        (enlargeLook)="onEnlarge(look)"
        [disableSelect]="isPaidForMember(member)"
      ></app-look-mannequin>
    </div>
  </div>
</div>
