import { Component, Input, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@jfw-library/ecommerce/core';
import { EventMember, Look } from 'common-types';
import { LookDetailsModalComponent } from '../../modals/look-details/look-details.component';

@Component({
  selector: 'app-review-event-look-card',
  templateUrl: './review-event.component.html',
  styleUrls: [
    './review-event.component.scss',
    '../../../event-manager.component.scss',
  ],
})
export class ReviewEventLookCardComponent implements OnInit {
  @Input() look: Look | undefined;
  @Input() members: EventMember[] | undefined;

  user = toSignal(this.authService.user$);

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
  }

  onDetails(): void {
    this.dialog.open(LookDetailsModalComponent, {
      minWidth: '300px',
      data: this.look,
      panelClass: 'dialog-modal',
      autoFocus: false,
    });
  }

  loggedInUserEmail(member: EventMember): boolean {
    return member.email === this.user()?.email;
  }
}
