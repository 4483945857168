<h1 class="review-measurements-header">Add/View Measurements</h1>
<div *ngIf="unmeasuredMembers.length > 0" class="add-measurements-container">
  <div class="header-container">
    <h3>Add Measurements</h3>
    <p>
      Use our digital measurement tool to get fitted from home or manually enter
      your measurements if you know them.
    </p>
  </div>

  <div class="accordion-container">
    <mat-accordion multi="true">
      <ng-container *ngFor="let member of unmeasuredMembers; index as i">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ member.firstName }} {{ member.lastName }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            <strong>SIZE THIS ORDER USING...</strong>
          </p>
          <app-finalize-measurements
            [member]="member"
            [event]="event"
            [skipStepOption]="false"
            [reviewMeasurements]="true"
            [submitOption]="true"
            [measurementForm]="measurementForms.controls[i]"
            (updatedMember)="saveMeasurements($event)"
            [updatingMeasurements]="updatingMeasurementsMemberIndex[i]"
          >
          </app-finalize-measurements>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </div>
</div>

<div *ngIf="measuredMembers.length > 0" class="view-measurements-container">
  <div class="header-container">
    <h3>View Measurements</h3>
    <p>
      To change your measurements, please contact Customer Service at
      866-783-5767.
    </p>
  </div>
  <div class="accordion-container">
    <mat-accordion multi="true">
      <ng-container *ngFor="let member of this.measuredMembers; index as i">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ member.firstName }}
              {{ member.lastName }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-view-measurements [measurementForm]="viewOnlyForms.controls[i]">
          </app-view-measurements>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </div>
</div>
