<div class="main-event" style="min-height: 425px">
  <div class="header">
    <!-- <h1 class="hide-lt-sm" data-cy="select-look">Add/Select Look</h1> -->
    <button
      mat-flat-button
      color="primary"
      routerLink="/ensembles/suits-and-tuxedos"
      [queryParams]="{ event: event.id }"
    >
      <span> Add New Look </span>
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <p class="event-step-copy">
    Add several looks while comparing. Once you choose your look, you're ready
    for next steps.
  </p>
  <div *ngIf="validatorMsg && nextClicked" class="event-step-validator-msg">
    <strong>{{ validatorMsg }}</strong>
  </div>

  <div class="add-looks-main-wrapper">
    <div class="add-look-container">
      <div
        class="add-look-box"
        routerLink="/ensembles/suits-and-tuxedos"
        [queryParams]="{ event: event.id }"
      >
        Add New Look
        <p
          style="
            font-size: 8em;
            font-weight: 400;
            line-height: 50px;
            margin-top: 14px;
            margin-bottom: 0px;
          "
        >
          +
        </p>
      </div>
    </div>
    <div *ngIf="useCarousel()" class="look-card-carousel">
      <app-carousel
        [ariaLabel]="'Carousel of looks'"
        [mobileDisplayCount]="1"
        [desktopDisplayCount]="3"
        [initialIndex]="memberLookIndex"
      >
        <ng-container *ngFor="let look of event.looks">
          <ng-container *appCarouselItem>
            <app-select-look-card
              data-cy="look-card"
              [event]="event"
              [look]="look"
              [selectedLookId]="selectedLookId"
              [purchasedLooks]="purchasedLooks"
              [nextClicked]="nextClicked"
              [saving]="saving"
              (selectLook)="selectLook($event)"
              (copyLook)="copyLook(look.id)"
              (deleteLook)="deleteLook($event)"
            ></app-select-look-card>
          </ng-container>
        </ng-container>
      </app-carousel>
    </div>

    <div
      *ngIf="!useCarousel()"
      class="look-card-grid"
      style="margin-left: 20px"
    >
      <ng-container *ngFor="let look of event.looks">
        <app-select-look-card
          data-cy="look-card"
          [event]="event"
          [look]="look"
          [selectedLookId]="selectedLookId"
          [purchasedLooks]="purchasedLooks"
          [nextClicked]="nextClicked"
          [saving]="saving"
          (selectLook)="selectLook($event)"
          (copyLook)="copyLook(look.id)"
          (deleteLook)="deleteLook($event)"
        ></app-select-look-card>
      </ng-container>
    </div>
  </div>
</div>
