<!-- <h1>Track Progress</h1>
<p>
  Manage your event and keep track of where your party members are in the order
  process.
</p> -->

@if (event()) {
  <div class="copy">
    <!-- <h1 class="hide-lt-sm">Track Progress</h1> -->
    <p *ngIf="!membersAvailable()" style="margin-bottom: 20px">
      <strong
        >There is currently no tracking information to display for this
        order.</strong
      >
    </p>
    <p *ngIf="isAdminOrOrganizer() && !isIndividual()">
      <a class="link" (click)="scrollToView()">Manage your event</a>
      and keep track of where your party members are in the order process
    </p>
    <p *ngIf="isIndividual() || isMemberView()">
      Keep track of the status of your event
    </p>
    <!-- <p *ngIf="isMemberView()">
    <a (click)="scrollToView()">Manage your event</a>
    and keep track of status for each of your paid member(s)
  </p> -->
  </div>

  <div class="timeline">
    <h3>Event Timeline</h3>
    <app-event-timeline [event]="event()"></app-event-timeline>
  </div>

  <table
    *ngIf="membersAvailable()"
    mat-table
    [dataSource]="members()"
    class="hide-lt-sm mat-elevation-z8"
  >
    <ng-container matColumnDef="memberName">
      <th mat-header-cell *matHeaderCellDef>Party Member</th>
      <td mat-cell *matCellDef="let member">
        <div class="member-name">
          {{ member.firstName }} {{ member.lastName }}
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="inviteSent">
      <th mat-header-cell *matHeaderCellDef>
        <span class="desktop">Invite<br />Sent</span>
        <mat-icon class="mobile">drafts</mat-icon>
      </th>
      <td mat-cell *matCellDef="let member">
        <mat-icon
          *ngIf="
            member.memberProgress !== undefined &&
            member.memberProgress.inviteSent !== undefined
          "
          title="Re-Send Invite"
          >check
        </mat-icon>

        <span
          *ngIf="
            member.memberProgress !== undefined &&
            member.memberProgress.inviteSent == undefined
          "
          >N/A
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="inviteAccepted">
      <th mat-header-cell *matHeaderCellDef>
        <span class="desktop">Invite<br />Accepted</span>
      </th>
      <td mat-cell *matCellDef="let member">
        <mat-icon
          *ngIf="
            member.memberProgress !== undefined &&
            member.memberProgress.inviteAccepted !== undefined
          "
          >check
        </mat-icon>

        <span
          *ngIf="
            member.memberProgress !== undefined &&
            member.memberProgress.inviteAccepted === undefined &&
            member.memberProgress.paid !== undefined
          "
          >N/A
        </span>

        <mat-icon
          style="color: red"
          *ngIf="
            (eventIsNearAccepted() && member.memberProgress === undefined) ||
            (eventIsNearAccepted() &&
              member.memberProgress !== undefined &&
              member.memberProgress.inviteAccepted === undefined &&
              member.memberProgress.paid === undefined)
          "
          >priority_high
        </mat-icon>
      </td>
    </ng-container>

    <mat-icon>local_offer</mat-icon>
    <ng-container matColumnDef="measurements">
      <th mat-header-cell *matHeaderCellDef>
        <span class="desktop">Measurements</span>
        <mat-icon class="mobile">local_offer</mat-icon>
      </th>
      <td mat-cell *matCellDef="let member">
        <mat-icon
          *ngIf="
            member.memberProgress !== undefined && measurementsCompleted(member)
          "
          >check
        </mat-icon>
        <mat-icon
          style="color: red"
          *ngIf="
            (eventIsNearMeasurements() &&
              member.memberProgress === undefined) ||
            (eventIsNearMeasurements() &&
              member.memberProgress !== undefined &&
              !measurementsCompleted(member))
          "
          >priority_high
        </mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="paid">
      <th mat-header-cell *matHeaderCellDef>
        <span class="desktop">Paid</span>
        <mat-icon class="mobile">attach_money</mat-icon>
      </th>
      <td mat-cell *matCellDef="let member">
        <mat-icon
          *ngIf="
            member.memberProgress !== undefined &&
            member.memberProgress?.paid !== undefined
          "
          >check
        </mat-icon>
        <mat-icon
          style="color: red"
          *ngIf="
            (eventIsNearPaid() && member.memberProgress === undefined) ||
            (eventIsNearPaid() &&
              member.memberProgress !== undefined &&
              member.memberProgress?.paid === undefined)
          "
          >priority_high
        </mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="shipped">
      <th mat-header-cell *matHeaderCellDef>
        <span class="desktop">Shipped</span>
        <mat-icon class="mobile">local_shipping</mat-icon>
      </th>
      <td mat-cell *matCellDef="let member">
        <img
          *ngIf="
            member.memberProgress !== undefined &&
            member.memberProgress?.shipped !== undefined
          "
          class="shipping-icon"
          src="assets/images/icons/review-event/shipped.png"
          alt=""
          (click)="openShippingModal(member)"
        />
      </td>
    </ng-container>
    <!-- EXT-1997
  <ng-container matColumnDef="returned">
    <th mat-header-cell *matHeaderCellDef>
      <span class="desktop">Returned</span>
      <mat-icon class="mobile">rotate_90_degrees_ccw</mat-icon>
    </th>
    <td mat-cell *matCellDef="let member">
      <mat-icon
        *ngIf="
          member.memberProgress !== undefined &&
          member.memberProgress?.returned !== undefined
        "
        >check
      </mat-icon>
    </td>
  </ng-container>
-->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>
        <span class="desktop">Resend Invite(s)</span>
      </th>
      <td mat-cell *matCellDef="let member">
        <!-- <mat-checkbox
        (change)="event ? selection.toggle(member) : null"
        [checked]="selection.isSelected(member)"
      ></mat-checkbox> -->
        <mat-checkbox
          (change)="
            event() ? selectInviteToggle($event, selection, member) : null
          "
          [disabled]="sendingInvites()"
          [checked]="selection.isSelected(member)"
        ></mat-checkbox>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns()"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns()"></tr>
  </table>
  <div class="event-date-lock-msg desktop" *ngIf="isEventDateLocked()">
    <span>{{ isEventDateLockedMsg() }}</span>
  </div>

  <!-- MOBILE: Timeline-->
  <div class="hide-gt-xs" style="border: 1px solid silver">
    <div style="text-align: center; border-bottom: 1px solid silver">
      <h3 class="mobile-party-members">Party Members</h3>
    </div>

    <div style="padding: 10px">
      <mat-accordion *ngFor="let member of members(); let i = index">
        <mat-expansion-panel
          (click)="toggleExpandedMobilePanels(i)"
          [expanded]="expandedMobileMembersPanel[i]"
        >
          <mat-expansion-panel-header>
            <mat-panel-title class="party-member">
              <div class="mobile-name-header-container">
                <span>{{ member.firstName }} {{ member.lastName }}</span>
                <!-- IA: Warning -->
                <!-- Mobile explanation warning if any of the nested fields are warnings -->
                <mat-icon
                  class="mbl-vertical-align-icon-warn"
                  *ngIf="
                    (eventIsNearAccepted() &&
                      member.memberProgress === undefined) ||
                    (eventIsNearAccepted() &&
                      member.memberProgress !== undefined &&
                      member.memberProgress.inviteAccepted === undefined &&
                      member.memberProgress.paid === undefined) ||
                    (eventIsNearMeasurements() &&
                      member.memberProgress === undefined) ||
                    (eventIsNearMeasurements() &&
                      member.memberProgress !== undefined &&
                      !measurementsCompleted(member)) ||
                    (eventIsNearPaid() &&
                      member.memberProgress === undefined) ||
                    (eventIsNearPaid() &&
                      member.memberProgress !== undefined &&
                      member.memberProgress?.paid === undefined)
                  "
                >
                  priority_high</mat-icon
                >
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div style="display: flex; flex-direction: column">
            <!-- Mobile: Invite Sent -->
            <div
              class="mbl-action"
              *ngIf="isAdminOrOrganizer() && !isIndividual()"
            >
              <div class="mbl-check-label">Invite Sent</div>
              <!-- IS: Done -->
              <div class="mbl-checkbox-frame">
                <mat-icon
                  class="mbl-vertical-align-icon"
                  *ngIf="
                    member.memberProgress !== undefined &&
                    member.memberProgress?.inviteSent !== undefined
                  "
                  >done
                </mat-icon>
                <span
                  *ngIf="
                    member.memberProgress !== undefined &&
                    member.memberProgress.inviteSent == undefined
                  "
                  >N/A
                </span>
              </div>
            </div>

            <!-- Mobile: Invite Accepted -->
            <div
              class="mbl-action"
              *ngIf="isAdminOrOrganizer() && !isIndividual()"
            >
              <div class="mbl-check-label">Invite Accepted</div>
              <div class="mbl-checkbox-frame">
                <!-- IA: Done -->
                <mat-icon
                  class="mbl-vertical-align-icon"
                  *ngIf="
                    member.memberProgress !== undefined &&
                    member.memberProgress?.inviteAccepted !== undefined
                  "
                  >done
                </mat-icon>

                <!-- IA: Not Applicable  -->
                <div
                  class="mbl-na"
                  *ngIf="
                    member.memberProgress !== undefined &&
                    member.memberProgress.inviteAccepted === undefined &&
                    member.memberProgress.paid !== undefined
                  "
                >
                  N/A
                </div>

                <!-- IA: Warning -->
                <mat-icon
                  class="mbl-vertical-align-icon-warn"
                  *ngIf="
                    (eventIsNearAccepted() &&
                      member.memberProgress === undefined) ||
                    (eventIsNearAccepted() &&
                      member.memberProgress !== undefined &&
                      member.memberProgress.inviteAccepted === undefined &&
                      member.memberProgress.paid === undefined)
                  "
                >
                  priority_high</mat-icon
                >
              </div>
            </div>

            <!-- Mobile: Measurements -->
            <div class="mbl-action">
              <div
                *ngIf="
                  member.memberProgress?.paid !== undefined &&
                  !measurementsCompleted(member)
                "
                class="mbl-check-label link"
                (click)="routeToMeasurements()"
              >
                Measurements
              </div>
              <div
                *ngIf="
                  member.memberProgress?.paid === undefined ||
                  measurementsCompleted(member)
                "
                class="mbl-check-label"
              >
                Measurements
              </div>
              <div class="mbl-checkbox-frame">
                <!-- Measurements: Done -->
                <mat-icon
                  class="mbl-vertical-align-icon"
                  *ngIf="
                    member.memberProgress !== undefined &&
                    measurementsCompleted(member)
                  "
                  >done
                </mat-icon>

                <!-- Measurements: Warning -->
                <mat-icon
                  class="mbl-vertical-align-icon-warn"
                  *ngIf="
                    (eventIsNearMeasurements() &&
                      member.memberProgress === undefined) ||
                    (eventIsNearMeasurements() &&
                      member.memberProgress !== undefined &&
                      !measurementsCompleted(member))
                  "
                >
                  priority_high</mat-icon
                >
              </div>
            </div>

            <!-- Mobile: Paid -->
            <div class="mbl-action">
              <div class="mbl-check-label">Paid</div>
              <div class="mbl-checkbox-frame">
                <!-- Paid: Done-->
                <mat-icon
                  class="mbl-vertical-align-icon"
                  *ngIf="
                    member.memberProgress !== undefined &&
                    member.memberProgress?.paid !== undefined
                  "
                  >done</mat-icon
                >

                <!-- Paid: Warning-->
                <mat-icon
                  class="mbl-vertical-align-icon-warn"
                  *ngIf="
                    (eventIsNearPaid() &&
                      member.memberProgress === undefined) ||
                    (eventIsNearPaid() &&
                      member.memberProgress !== undefined &&
                      member.memberProgress?.paid === undefined)
                  "
                  >priority_high
                </mat-icon>
              </div>
            </div>

            <!-- Mobile: Shipped -->
            <div class="mbl-action">
              <div class="mbl-check-label">
                Shipped
                <span
                  *ngIf="
                    member.memberProgress !== undefined &&
                    member.memberProgress?.shipped !== undefined
                  "
                >
                  -
                  <span class="link" (click)="this.openShippingModal(member)"
                    >Tracking Info</span
                  >
                </span>
              </div>
              <div class="mbl-checkbox-frame">
                <!-- Shipped: Done -->
                <mat-icon
                  class="mbl-vertical-align-icon"
                  *ngIf="
                    member.memberProgress !== undefined &&
                    member.memberProgress?.shipped !== undefined
                  "
                  >done
                </mat-icon>
              </div>
            </div>

            <!-- Mobile: Returned EXT-1997
          <div class="mbl-action">
            <div class="mbl-check-label">Returned</div>
            <div class="mbl-checkbox-frame">


              <mat-icon
                class="mbl-vertical-align-icon"
                *ngIf="
                  member.memberProgress !== undefined &&
                  member.memberProgress?.returned !== undefined
                "
                >done
              </mat-icon>
            </div>
          </div>
          -->

            <!-- Mobile: Resend Invite-->
            <div
              class="mbl-action"
              *ngIf="isAdminOrOrganizer() && !isIndividual()"
            >
              <div class="mbl-check-label">Resend Invite</div>
              <div class="mbl-checkbox-frame">
                <!-- <mat-checkbox
                (change)="event ? selection.toggle(member) : null"
                [checked]="selection.isSelected(member)"
              ></mat-checkbox> -->
                <!-- <mat-checkbox
                class="checkbox-invite"
                (change)="
                  event ? selectInviteToggle($event, selection, member) : null
                "
                [checked]="selection.isSelected(member)"
              ></mat-checkbox> -->
                <input
                  type="checkbox"
                  class="checkbox-invite"
                  [disabled]="sendingInvites()"
                  (change)="
                    event()
                      ? selectInviteToggle($event, selection, member)
                      : null
                  "
                  [checked]="selection.isSelected(member)"
                />
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <div class="event-date-lock-msg mobile" *ngIf="isEventDateLocked()">
        <span>{{ isEventDateLockedMsg() }}</span>
      </div>
      <div style="text-align: center; margin-top: 20px">
        <button
          *ngIf="isAdminOrOrganizer() && !isIndividual()"
          mat-flat-button
          color="primary"
          [disabled]="!(selection.selected.length > 0)"
          (click)="sendSelectedInvites()"
        >
          RESEND INVITES
        </button>
      </div>
    </div>
  </div>

  <div
    class="reminder-buttons-container"
    *ngIf="isAdminOrOrganizer() && !isIndividual()"
  >
    <div class="invites-sent-msg" *ngIf="invitesResent() && !errorSending()">
      The invites have been resent.
    </div>
    <div class="invites-error-msg" *ngIf="errorSending()">
      There was an issue resending the invites.
    </div>
    <button
      class="reminder-button desktop"
      style="margin-left: 20px"
      mat-flat-button
      color="primary"
      [disabled]="!(selection.selected.length > 0)"
      (click)="sendSelectedInvites()"
    >
      <ng-container *ngIf="!sendingInvites(); else spinner">
        RESEND INVITES
      </ng-container>

      <ng-template #spinner>
        <mat-progress-spinner
          color="accent"
          value="100"
          mode="indeterminate"
          style="margin-left: auto; margin-right: auto"
          diameter="20"
          strokeWidth="2"
        >
        </mat-progress-spinner>
      </ng-template>
    </button>
  </div>

  <div class="your-look" *ngIf="isIndividual()">
    <h2 class="look-header">Your Look</h2>
    <div class="track-progress-look">
      @if (individualLook(); as look) {
        <app-review-event-look-card [look]="look"></app-review-event-look-card>
      }
    </div>
  </div>

  <div #bottomContainer></div>

  <div *ngIf="isIndividual()" class="manage-event-container">
    <h2 #manageSection>Manage Event</h2>
    <a routerLink="../review-measurements">Add/View Measurements</a><br />
    <a routerLink="/request-return-label">Request Return Label</a><br />
  </div>

  <div
    *ngIf="isAdminOrOrganizer() && !isIndividual()"
    class="manage-event-container"
  >
    <h2 #manageSection>Manage Event</h2>

    <div class="manage-event-links">
      <div class="manage-event-col">
        <a routerLink="../add-looks">Add/Edit Looks</a><br />
        <a routerLink="../add-members">Add/Edit Members</a><br />
        <a routerLink="../assign-looks">Assign Looks</a><br />
        <a
          [ngClass]="isEventDateLocked() ? 'disabled' : ''"
          routerLink="../review-measurements"
          >Add/View Measurements</a
        ><br />
      </div>
      <div class="manage-event-col">
        <a
          [ngClass]="isEventDateLocked() ? 'disabled' : ''"
          routerLink="/checkout"
          >Pay For Another Member</a
        ><br />
        <a
          [routerLink]="['../details']"
          [queryParams]="{ previousRoute: 'track-progress' }"
          >Add Co-Owner</a
        ><br />
        <a routerLink="/request-return-label">Request Return Label</a>
      </div>
    </div>
  </div>

  <div
    *ngIf="!isAdminOrOrganizer() && !isIndividual()"
    class="manage-event-container"
  >
    <h2 #manageSection>Manage Event</h2>
    <a routerLink="../review-measurements">Add/View Measurements</a><br />
    <a routerLink="/checkout">Pay For Another Member</a><br />
    <a routerLink="/request-return-label">Request Return Label</a><br />
  </div>

  <div class="back-to-top-container">
    <button
      *ngIf="showScrollToTop()"
      class="back-to-top-button"
      mat-stroked-button
      (click)="scrollToTop()"
    >
      <mat-icon class="back-to-top-icon">keyboard_double_arrow_up</mat-icon>
      <span>BACK TO TOP</span>
    </button>
  </div>
} @else {
  <div class="event-loader-container">
    <div class="inner">
      <mat-progress-spinner
        color="primary"
        class="progress-spinner"
        value="100"
        mode="indeterminate"
        diameter="120"
        strokeWidth="7"
      >
      </mat-progress-spinner>
    </div>
  </div>
}
