import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Event, Look } from 'common-types';

@Component({
  selector: 'app-select-look-card',
  templateUrl: './select-look.component.html',
  styleUrls: [
    './select-look.component.scss',
    '../../../event-manager.component.scss',
  ],
})
export class SelectLookCardComponent {
  @Input() event!: Event;
  @Input() look!: Look;
  @Input() selectedLookId: string | undefined;
  @Input() nextClicked!: boolean;
  @Input() saving = false;
  @Input() purchasedLooks!: Array<string | undefined>;
  @Output() selectLook = new EventEmitter<Look>();
  @Output() deleteLook = new EventEmitter<string>();
  @Output() copyLook = new EventEmitter<string>();

  disabledLookText = 'Cannot modify purchased looks';

  constructor() {}

  isSelected(): boolean {
    return this.look.id === this.selectedLookId;
  }

  cardRequired(): boolean {
    return this.nextClicked && !this.event.members[0]?.memberLook;
  }

  disableModify(): boolean {
    return this.paidMember() && this.isSelected();
  }

  paidMember(): boolean {
    const member = this.event.members[0];
    return member?.memberProgress?.paid !== undefined;
  }

  onSelect(): void {
    if (this.saving) return;
    this.selectLook.emit(this.look);
  }

  onCopy(): void {
    if (this.saving) return;
    this.copyLook.emit(this.look.id);
  }

  onDelete(): void {
    if (this.saving) return;
    this.deleteLook.emit(this.look.id);
  }
}
