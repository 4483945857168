<div class="modal-main">
  <div class="close-modal">
    <!-- No Button -->
  </div>

  <div class="modal-header-container">
    <h3>Confirm Change</h3>
  </div>

  <mat-dialog-content>


    @if(updatingEvent()) {
      <div class="container">
        <h4>Updating Event</h4>
        <mat-progress-spinner
          color="primary"
          mode="indeterminate"
          diameter="100"
          strokeWidth="7"
        >
        </mat-progress-spinner>
      </div>
    }

    @else {
        <p>Are you sure you want to change
          @if(action === 'switch-to-individual') {
          to an <strong>individual</strong> event?
          } @else if(action === 'switch-to-group') {
            to a <strong>group</strong> event?
          } @else {
            your event?
          }
        </p>
        @switch(action) {
          @case('switch-to-individual'){
              @if((event?.members?.length ?? 0) > 0){
                <p>This change will remove all members from the event.</p>
              }
              @if((event?.looks?.length ?? 0) > 0) {
                <p>This change will NOT remove the {{(event?.looks?.length ?? 0) === 1 ? 'look' : 'looks' }} you have saved.</p>
              }
          }
          @case('switch-to-group'){
            <p>This will allow you to add multiple members to the event.</p>
          }
        }
        <mat-dialog-actions align="center">
          <button
            mat-flat-button
            color="primary"
            style="margin-right: 20px; min-width: 100px;"
            (click)="confirmChange()"
          >
            Yes
          </button>

          <button
            mat-stroked-button
            color="accent"
            (click)="closeDialog(false)"
            style="min-width: 100px;"
          >
            No
          </button>

        </mat-dialog-actions>
    }

  </mat-dialog-content>
</div>
